import React from 'react';

import Pill, { PillTheme } from '@/components/UI/Pill';
import { AvatarShape, AvatarSize } from '@/components/cards/Avatar';
import AvatarInfo from '@/components/cards/AvatarInfo';
import useDelegatedNfts from '@/hooks/useDelegatedNfts';
import useNftsOwned from '@/hooks/useNftsOwned';
import { BucketType, createBucketURL } from '@/utilities/createBucketUrl';
import { cleanAddress } from '@/utilities/functions';

interface Props {
  account: string;
}

const LicensableNftTable = ({ account }: Props) => {
  const { loading: loadingOwned, nfts: ownedNfts } = useNftsOwned(account);
  const { loading: loadingDelegated, delegatedNfts } =
    useDelegatedNfts(account);

  // Mark each NFT collection to determine its source
  const markedOwnedNfts = ownedNfts.map((nft) => ({ ...nft, source: 'owned' }));
  const markedDelegatedNfts = delegatedNfts.map((nft) => ({
    ...nft,
    source: 'delegated',
  }));

  // Combine NFTs once they're loaded
  const combinedNfts = [...markedOwnedNfts, ...markedDelegatedNfts];

  return (
    <div className="flex w-full flex-col items-center justify-center gap-2">
      {combinedNfts.length > 0
        ? combinedNfts.map((nftCollection) => (
            <div
              key={nftCollection.name}
              className="flex w-full flex-col items-center justify-center rounded-lg bg-white"
            >
              <div className="flex w-full flex-col self-start py-2 pl-4">
                <AvatarInfo
                  count={nftCollection.nfts.length}
                  image={createBucketURL(
                    BucketType.Collection,
                    cleanAddress(nftCollection.address)
                  )}
                  verified={true}
                  size={AvatarSize.sm}
                  title={
                    nftCollection.source === 'delegated'
                      ? nftCollection.name + ' (delegated)'
                      : nftCollection.name
                  }
                  shape={AvatarShape.square}
                  primaryInfo={
                    <Pill
                      theme={PillTheme.GREEN} // Adjust if you want a different theme for delegated
                      showDot={true}
                      pulse={true}
                    >
                      Licensable Asset
                    </Pill>
                  }
                />
              </div>
            </div>
          ))
        : null}

      {combinedNfts.length === 0 && !loadingOwned && !loadingDelegated && (
        <div className="flex w-full flex-col items-center justify-center gap-2 rounded-lg bg-neutral-10">
          <div className="flex w-full flex-col px-2 py-6 pl-4 text-center text-neutral-100">
            Unfortunately, you don&apos;t own any Licensable Assets.
          </div>
        </div>
      )}
    </div>
  );
};

export default LicensableNftTable;
