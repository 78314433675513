import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ImSpinner2 } from 'react-icons/im';

export enum ButtonType {
  primary = 'bg-primary-main hover:bg-primary-hover active:bg-primary-pressed text-white rounded-lg',
  secondary = 'bg-white active:bg-primary-pressed active:text-white hover:bg-primary-surface border-solid border-[1.5px] border-primary-main hover:border-transparent text-primary-main rounded-lg',
  primaryPill = 'bg-primary-main hover:bg-transparent border-solid border-[1.5px] border-transparent hover:border-primary-main hover:text-primary-main text-white rounded-full',
  secondaryPill = 'bg-transparent hover:bg-primary-main border-solid border-[1.5px] border-primary-main hover:border-transparent hover:text-white text-primary-main rounded-full',
  disabled = 'bg-neutral-10 text-neutral-50 rounded-lg cursor-not-allowed',
  drawer = 'bg-neutral-10 text-neutral-50 rounded-lg hover:bg-primary-surface pressed:bg-primary-pressed',
  primaryDisabled = 'bg-neutral-10 text-neutral-50 cursor-not-allowed border-solid border-transparent rounded-lg',
  link = 'text-primary-main px-0',
  outline = 'bg-transparent hover:bg-primary-surface border-solid border-[1.5px] border-neutral-10 hover:border-transparent text-neutral-100 rounded-lg',
  light = 'bg-primary-main active:bg-primary-pressed text-black rounded-lg border-[1.5px] border-primary-main hover:text-white hover:border-white hover:bg-accent-hover',
}

export enum ButtonSize {
  xs = 'py-2 px-5 text-sm',
  sm = 'p-3 text-md',
  full = 'py-3 text-md w-full',
  pill = 'p-2 text-lg',
  primary = 'py-2 px-6 text-md',
}

export interface ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  type: ButtonType;
  size: ButtonSize;
  classes?: string;
  parentClasses?: string;
  disabled?: boolean;
  loading?: boolean;
  mode?: 'submit' | 'button' | 'reset';
  loadingText?: string;
}

const Button = React.forwardRef(
  (
    {
      children,
      onClick,
      type,
      size,
      classes,
      parentClasses = 'flex',
      disabled,
      loading,
      loadingText,
      mode = 'button',
    }: ButtonProps,
    ref: any
  ): JSX.Element => {
    if (disabled) {
      type = ButtonType.disabled;
    }

    if (loading) {
      // Prevents the button from being clicked while loading
      disabled = true;
    }

    const className = `${size} ${type} ${classes}`;
    return (
      <div className={`${parentClasses} group`} ref={ref}>
        <button
          type={mode}
          className={className}
          onClick={onClick}
          disabled={disabled}
        >
          {loading ? (
            <span className="flex items-center justify-center overflow-clip">
              <ImSpinner2 size={20} className="flex-shrink-0 animate-spin" />
              {loadingText ? (
                <span className="flex-shrink-0 pl-2">{loadingText}</span>
              ) : null}
            </span>
          ) : (
            children
          )}
        </button>
        <FontAwesomeIcon
          className={
            type === ButtonType.link
              ? 'pl-[6px] text-[6px] text-transparent group-hover:text-primary-main'
              : 'hidden'
          }
          icon={faChevronRight}
        />
      </div>
    );
  }
);

Button.displayName = 'Button';

export default Button;
