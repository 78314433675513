import { createSlice } from '@reduxjs/toolkit';

import type { NftsByCollection } from '@/models/NftsByCollection';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  value: {} as { [key: string]: NftsByCollection[] },
  delegatedNfts: {} as { [key: string]: NftsByCollection[] },
};

const nftSlice = createSlice({
  name: 'userNFTs',

  initialState,

  reducers: {
    setUserNfts(
      state,
      action: PayloadAction<{ address: string; nfts: NftsByCollection[] }>
    ) {
      state.value[action.payload.address] = action.payload.nfts;
    },
    setDelegatedNftsStore(
      state,
      action: PayloadAction<{ address: string; nfts: NftsByCollection[] }>
    ) {
      state.delegatedNfts[action.payload.address] = action.payload.nfts;
    },
  },
});

export const { setUserNfts, setDelegatedNftsStore } = nftSlice.actions;

export default nftSlice.reducer;
