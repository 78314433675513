import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CHAIN_ID } from '@/constants/constants';
import { useAppSelector } from '@/hooks/useApp';
import type { Collections } from '@/models/Collections';
import type { NftsByCollection } from '@/models/NftsByCollection';
import { setUserNfts } from '@/store/nftSlice';
import { useGetCollectionsQuery } from '@/utilities/__generated__/graphql';
import { fetchNfts } from '@/utilities/fetchNfts';
import { compareAddress } from '@/utilities/functions';

const useNftsOwned = (
  account: string,
  requestedCollections?: Collections[]
) => {
  const [loading, setLoading] = useState(true);
  const [nftsBeforeFiltering, setNftsBeforeFiltering] = useState<
    NftsByCollection[]
  >([]);
  const [nfts, setNfts] = useState<NftsByCollection[]>([]);
  const [error, setError] = useState(null);

  const { data: collections } = useGetCollectionsQuery();

  const dispatch = useDispatch();

  const nftsOwnedFromState = useAppSelector((state) => state.userNFTs.value);

  useEffect(() => {
    if (!collections?.Collections) return;
    if (!account) return;

    // TODO the type returned from useGetCollectionsQuery doesn't match Collections[]
    // Types of property 'name' are incompatible.
    // Type 'string | null | undefined' is not assignable to type 'string'.
    // Type 'undefined' is not assignable to type 'string'

    if (nftsOwnedFromState[account]) {
      setNftsBeforeFiltering(nftsOwnedFromState[account]);
      return;
    }

    fetchNfts(account, collections?.Collections as Collections[], CHAIN_ID)
      .then((data) => {
        setNftsBeforeFiltering(data);
        dispatch(setUserNfts({ address: account, nfts: data }));
      })
      .catch((error) => {
        setError(error);
        return Promise.reject(error);
      });
  }, [account, collections?.Collections, dispatch, nftsOwnedFromState]);

  useEffect(() => {
    let nftsAfterFiltering = nftsBeforeFiltering;
    if (requestedCollections) {
      nftsAfterFiltering = nftsBeforeFiltering.filter((nft) => {
        return requestedCollections.some((collection) => {
          return compareAddress(collection.nft_contract_address, nft.address);
        });
      });
    }
    setNfts(nftsAfterFiltering);
    setLoading(false);
  }, [requestedCollections, nftsBeforeFiltering]);

  return { loading, error, nfts };
};
export default useNftsOwned;
