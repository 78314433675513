import type { CollectionFragment } from '@/utilities/__generated__/graphql';
import { compareAddress } from '@/utilities/functions';
import type { TypedDataDomain } from 'viem';

export const TOKEN_NAME = 'api_token';

interface errorMessagesInterface {
  [key: string]: any;
}

export const errorMessages: errorMessagesInterface = {
  'Could not verify JWT: JWTExpired': 'INVALID_JWT',
  'NetworkError when attempting to fetch resource.': 'NETWORK_ERROR',
};

export const etherscanTxUri = 'https://etherscan.io/tx/';

export enum DATE_FORMATS {
  MMDY = 'MMMM DD, YYYY',
  MMMDY = 'MMM DD, YYYY',
  MMMDYhm = 'MMM DD, YYYY hh:mm a',
  YYYYMMDD = 'YYYY-MM-DD',
  MMDDYYYYHHMMA = 'MM/DD/YYYY hh:mm a',
}

export const MAX_BIDS_PER_USER = 50;
export const ogImagePath = '/img/deal_page_og_image.png';
export const CHAIN_ID =
  process.env.NEXT_PUBLIC_ENABLE_POLYGON === 'true' ? 137 : 1;

export enum ENVIRONMENT_ENUM {
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
}

export const CURRENT_ENVIRONMENT = process.env
  .NEXT_PUBLIC_CURRENT_ENVIRONMENT as ENVIRONMENT_ENUM;

export enum ERROR_CODES {
  USER_UNAUTHORIZED = 'You are not authorized for this action.',
  JWT_NOT_EXISTS = 'Authorization token does not exist or is invalid.',
  INVALID_REFRESH_TOKEN = 'The refresh token is invalid.',

  MISSING_PARAMETER = 'A required parameter is missing.',
  INVALID_INPUTS = 'Some inputs provided are invalid.',
  DANGEROUS_INPUT = 'A potentially unsafe input value has been detected.',
  INVALID_TOKEN = ' The provided token is invalid.',

  UNSUPPORTED_DATA_TYPE = 'The provided data type is unsupported.',
  INVALID_NUMBER = 'The provided number is not large enough.',
  INVALID_TEXT = 'The provided text is invalid.',
  INVALID_PHONE = 'The provided phone number is invalid.',
  INVALID_EMAIL = 'The provided email address is invalid.',
  INVALID_DATE = 'The provided date is invalid.',
  NOT_A_BIG_NUMBER = 'The provided input is not a big number.',
  INVALID_COUNT = 'The provided amount is invalid.',
  INTERNAL_SERVER_ERROR = 'An internal server error has occurred.',
  METHOD_NOT_ALLOWED = 'The attempted method is not allowed.',
  LENGTH_MUST_BE_POSITIVE = 'The provided length must be greater than 0.',

  INVALID_SIGNATURE = 'The provided signature is invalid.',
  INVALID_ADDRESS = 'The provided address is invalid.',
  WRONG_COLLECTION = 'The provided collection is incorrect.',
  INVALID_INSTAGRAM_ADDRESS = 'The provided Instagram address is invalid.',
  INVALID_TWITTER_ADDRESS = 'The provided Twitter address is invalid.',
  INVALID_FACEBOOK_ADDRESS = 'The provided Facebook address is invalid.',
  INVALID_OPENSEA_ADDRESS = 'The provided OpenSea address is invalid.',

  INVALID_DEAL_STATUS = 'The deal status provided is invalid.',
  INVALID_BID_STATUS = 'The bid status provided is invalid.',
  INVALID_BID_AMOUNT = 'The bid quantity has been exceeded.',
  INVALID_PAYMENT_CURRENCY = 'The payment currency provided is invalid.',
  MIN_BUDGET_GREATER_MAX = 'Minimum budget must be less than the maximum budget.',
  MIN_BUDGET_GREATER_ZERO = 'Minimum budget must be greater than 0.',
  LICENSE_EXPIRATION_LESS_THAN_BIDDING_EXPIRATION = 'Bidding expiration must be earlier than license expiration.',

  INVALID_DEAL_CREATE = 'Error creating deal.',
  INVALID_NFT_CONTRACT_ADDRESS = 'The NFT contract address provided is invalid.',
  MISSING_METADATA = 'Missing NFT metadata from provider call',
  FAILED_FETCH_DELEGATIONS = 'Failed to fetch delegations.',
  UNEXPECTED_ERROR = 'An unexpected error has occurred.',
  HASURA_ERROR = 'Error fetching data from Hasura',
}

export const MAX_FILE_UPLOAD_SIZE = 5242880; // 5 MB

export const apolloURI = process.env.NEXT_PUBLIC_HASURA_PROJECT_ENDPOINT;

export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

export const AWS_S3_BUCKET_NAME = process.env.NEXT_PUBLIC_AWS_S3_BUCKET_NAME;
export const BUCKET_URL = process.env.NEXT_PUBLIC_BUCKET_URL;

export const CLOUDFLARE_BUCKET_URL =
  process.env.NEXT_PUBLIC_CLOUDFLARE_BUCKET_URL;

export const ACCESS_TOKEN_EXPIRES_IN = process.env.ACCESS_TOKEN_EXPIRES_IN;
export const REFRESH_TOKEN_EXPIRES_IN = process.env.REFRESH_TOKEN_EXPIRES_IN;

export const twitterBaseUrl = 'https://twitter.com';
export const instagramBaseUrl = 'https://instagram.com';

const USDC_ADDRESS = '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359';
const MOCK_USDC_ADDRESS = '0x3b0d7d487aef8585d5094c587df6762e1ca2f168';

// These is the addresses that use the default template. This will need to be updated if multiple wallets make deals with this template
export const IGLOO_COMPANY_TEMPLATE_ADDRESSES = [
  '0x8C7C9F5725b0eD38AfE5161Ae7C03eE76FDB3FC2', // Pudgy Penguins
  '0xC100cadd05DFCc7468DE97A9B2077c391a35AFc7', // Momentum
];

// The Polygon USDC uses a legacy EIP712 domain separator whihc uses salt instead of chainId
const USDC_EIP712_DOMAIN: TypedDataDomain = {
  name: 'USD Coin',
  version: '2',
  chainId: 137,
  verifyingContract: USDC_ADDRESS,
};
const MOCK_USDC_EIP712_DOMAIN: TypedDataDomain = {
  name: 'MyToken',
  version: '1',
  chainId: 137,
  verifyingContract: MOCK_USDC_ADDRESS,
};

export const PAYMENT_TOKEN_ADDRESS =
  (process.env.NEXT_PUBLIC_PAYMENT_TOKEN_ADDRESS as `0x${string}`) ||
  MOCK_USDC_ADDRESS;

export const PAYMENT_TOKEN_EIP712_DOMAIN: TypedDataDomain = compareAddress(
  PAYMENT_TOKEN_ADDRESS,
  USDC_ADDRESS
)
  ? USDC_EIP712_DOMAIN
  : MOCK_USDC_EIP712_DOMAIN;

export const REGISTRY_ADDRESS = process.env
  .NEXT_PUBLIC_REGISTRY_ADDRESS as `0x${string}`;

export const AGGREGATOR_ADDRESS = process.env
  .NEXT_PUBLIC_AGGREGATOR_ADDRESS as `0x${string}`;

export const VALIDATOR_ADDRESS = process.env
  .NEXT_PUBLIC_VALIDATOR_ADDRESS as `0x${string}`;

export const iglooContractsCollections: CollectionFragment[] = [
  {
    nft_contract_address: '0xBd3531dA5CF5857e7CfAA92426877b022e612cf8',
    name: 'Pudgy Penguins',
    is_verified: true,
  },
  {
    nft_contract_address: '0x524cAB2ec69124574082676e6F654a18df49A048',
    name: 'Lil Pudgys',
    is_verified: true,
  },
  {
    nft_contract_address: '0x062E691c2054dE82F28008a8CCC6d7A1c8ce060D',
    name: 'Pudgy Rods',
    is_verified: true,
  },
];

export const BIDS_VALIDATION_BATCH_SIZE = 100;
export const GELATO_API_KEY = process.env.NEXT_PUBLIC_GELATO_API_KEY;
