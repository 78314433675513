import { useEffect, useState } from 'react';
import { CHAIN_ID, ERROR_CODES } from '@/constants/constants';
import { fetchWithRetries } from '@/utilities/edge/fetchWithRetry';

// define Delegation type
type Delegation = {
  type: 'NONE' | 'ALL' | 'CONTRACT' | 'TOKEN';
  to: string;
  from: string;
  contract: string;
  right: string;
  tokenId: number | null;
  amount: number;
};

// define the type of the return value of the hook
type UseDelegationByWallet = {
  delegations: Delegation[] | null;
  loading: boolean;
  error: string | null;
};

const useDelegationByWallet = (wallet: string): UseDelegationByWallet => {
  const [delegations, setDelegations] = useState<Delegation[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchDelegations = async () => {
      setLoading(true);
      try {
        const response = await fetchWithRetries(
          `https://api.delegate.xyz/registry/v2/${wallet}?chainId=${CHAIN_ID}`
        );

        if (!response.ok) {
          throw new Error(ERROR_CODES.FAILED_FETCH_DELEGATIONS);
        }

        const data: Delegation[] = await response.json();
        setDelegations(data);
      } catch (error: any) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchDelegations();
  }, [wallet]);

  return { delegations, loading, error };
};

export default useDelegationByWallet;
