import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import fallbackImage from '@/public/overpass.svg';
import { AvatarBadge } from './AvatarBadge';
import type { MouseEvent } from 'react';

interface AvatarProps {
  src?: string | null;
  count?: number;
  address?: string;
  size: string;
  alt?: string;
  shape?: AvatarShape;
  link?: string;
  showBorder?: boolean;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  unoptimized?: boolean;
  linkExternal?: boolean;
}

// TODO: Add min and max to all sizes - figure out why sm is causing issues unless you specify 100px
export enum AvatarSize {
  xxs = 'w-[30px] h-[30px] relative bg-clip-border',
  xs = 'relative h-[48px] w-[48px] bg-clip-border',
  sm = 'md:max-w-[56px] md:max-h-[56px] max-w-[38px] max-h-[38px] h-[100px] w-[100px] relative bg-clip-border',
  md = 'lg:w-[82px] lg:h-[82px] w-[60px] h-[60px] relative  bg-clip-border',
  lg = 'lg:max-w-[101px] lg:max-h-[101px] max-w-[83px] max-h-[83px] h-[101px] w-[101px] relative bg-clip-border',
  xl = 'w-[136px] h-[136px] md:w-[160px] md:h-[160px] relative',
  xxl = 'w-[74px] h-[74px] md:w-[160px] md:h-[160px] relative  bg-clip-border ',
  square = 'lg:w-[70px] lg:h-[70px] w-[70px] h-[70px] relative  bg-clip-border',
}

export enum AvatarShape {
  circle = 'rounded-full',
  square = 'rounded-lg',
}

const Avatar = ({
  src = fallbackImage.src,
  count,
  size,
  alt,
  link,
  shape = AvatarShape.circle,
  showBorder = false,
  unoptimized = false,
}: AvatarProps) => {
  const [imageSrc, setImageSrc] = useState(src);
  const router = useRouter();
  const altText = `${alt} avatar`;

  useEffect(() => {
    setImageSrc(src);
  }, [src]);

  const conditionallyRoute = (event: any) => {
    // eslint-disable-next-line no-console
    console.log('Avatar.ConditionallyRoute');
    if (link) {
      event.preventDefault();
      void router.push(link);
    }
  };

  return (
    <div
      className={`${size} ${shape} ${
        showBorder ? 'ring-[5px] ring-accent-pressed' : ''
      }`}
      onClick={conditionallyRoute}
    >
      <Image
        unoptimized={unoptimized}
        onError={() => {
          setImageSrc(fallbackImage.src);
        }}
        className={`bg-primary-surface ${shape}`}
        layout="fill"
        alt={altText}
        src={imageSrc as string}
      />
      {count ? <AvatarBadge count={count} /> : undefined}
    </div>
  );
};

export default Avatar;
