import { MagicAuthConnector } from '@magiclabs/wagmi-connector';
import { polygon } from 'wagmi/chains';
import tailwindConfig from '@/tailwind.config';

export const magicConnector = new MagicAuthConnector({
  chains: [polygon as any],
  options: {
    apiKey: `${process.env.NEXT_PUBLIC_MAGIC_PUB_KEY}`,
    oauthOptions: {
      providers: ['google', 'twitter'],
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    accentColor: tailwindConfig?.theme?.extend?.colors?.primary?.main,
    customHeaderText: 'OverpassIP',
    customLogo: 'https://overpass-ip.s3.amazonaws.com/overpassip/logo',
    magicSdkConfiguration: {
      network: {
        rpcUrl: polygon.rpcUrls.public.http[0],
        chainId: 137,
      },
    },
  },
});
