import { BUCKET_URL } from '@/constants/constants';

export enum BucketType {
  Brand = 'brand',
  User = 'user',
  Collection = 'collection',
}

export const createBucketURL = (
  type: BucketType | string | undefined = '',
  path: string | null | undefined = null,
  updatedAt?: string
) => {
  if (!BUCKET_URL) return '';
  const encodedPath = encodeURIComponent(path ?? '');
  const formattedURL = `${BUCKET_URL}${type}/${encodedPath}${
    updatedAt ? `?${updatedAt}` : ''
  }`;

  return formattedURL;
};
