import React from 'react';
import { useSelector } from 'react-redux';

import { AuthState } from '@/types/Account';

const WalletContainer = (props: any) => {
  const authState: AuthState = useSelector(
    (state: any) => state.account.authState
  );

  const statusShow = authState === AuthState.disconnected ? 'hidden' : '';
  return (
    <div className="flex flex-col px-8 py-14">
      {authState === AuthState.disconnected && (
        <div className={'flex items-center'}>
          <h2 className="py-2 font-gt-haptik text-heading-5-mobile font-bold leading-6 text-black md:text-heading-5">
            Connect wallet
          </h2>
          <span
            className={`mx-4 h-[14px] w-[14px] rounded-[50%] ${statusShow} bg-success-main`}
          />
        </div>
      )}
      {props.children}
    </div>
  );
};

export default WalletContainer;
