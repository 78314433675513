import { ApolloProvider } from '@apollo/client';
import { gsap } from 'gsap';
import { useRouter } from 'next/router';
import NextNProgress from 'nextjs-progressbar';
import React, { useEffect, useState } from 'react';

import GlobalConfig from '@/app.config';
import ToastContainer from '@/components/UI/toast/ToastContainer';
import useApolloClientWithSession from '@/hooks/useApolloClientWithSession';
import { useScrollBasedAnims } from '@/hooks/useScrollBasedAnims';
import useWindowSize from '@/hooks/useWindowSize';
import initStyle from '@/styles/Init.module.scss';
import Footer from './Footer';
import NavBar from './NavBar';

/* eslint-disable */
const Layout = ({ children }: any) => {
  const apolloClient = useApolloClientWithSession();

  const { width, height } = useWindowSize();
  const [once, setOnce] = useState(false);
  let onceVar = false;
  useEffect(() => {
    if (once || onceVar) {
      return;
    }
    setOnce(true);

    onceVar = true;
    // @ts-ignore
    const timeline = new gsap.timeline({ paused: true });
    const globalMask = document.getElementById('global-mask');
    const header = document.getElementById('header');
    // @ts-ignore
    const logo = header.querySelector('#header-logo');
    // @ts-ignore
    const chars = header.querySelectorAll('.char');
    // @ts-ignore
    const tinyChars = header.querySelectorAll('.tiny-char');
    // @ts-ignore
    const initiallyHiddenEls = header.querySelectorAll('.initially-hidden');
    // @ts-ignore
    const logoBounds = logo.getBoundingClientRect();
    const xShift = width / 2 - logoBounds.left - logoBounds.width / 2;
    const yShift = height / 2 - logoBounds.top - logoBounds.height / 2;
    gsap.set(logo, {
      x: xShift,
      y: yShift,
      scale: width > 767 ? 1.6 : 1.4,
      autoAlpha: 1,
    });
    const oddChars = [];
    const evenChars = [];
    for (let i = 0; i < chars.length; i++) {
      if (i % 2 === 0) {
        evenChars.push(chars[i]);
      } else {
        oddChars.push(chars[i]);
      }
    }
    timeline
      .fromTo(
        evenChars,
        { y: -12 },
        {
          x: 0,
          y: 0,
          rotation: 0,
          duration: 0.35,
          ease: 'sine.out',
          force3D: true,
        },
        0
      )
      .fromTo(
        evenChars,
        { autoAlpha: 0 },
        { autoAlpha: 1, duration: 0.34, ease: 'sine.inOut', force3D: true },
        0.05
      )
      .fromTo(
        oddChars,
        { y: 12 },
        {
          x: 0,
          y: 0,
          rotation: 0,
          duration: 0.35,
          ease: 'sine.out',
          force3D: true,
        },
        0
      )
      .fromTo(
        oddChars,
        { autoAlpha: 0 },
        { autoAlpha: 1, duration: 0.34, ease: 'sine.inOut', force3D: true },
        0.05
      )
      .fromTo(
        tinyChars,
        { autoAlpha: 0 },
        { autoAlpha: 1, duration: 0.3, ease: 'sine.inOut', force3D: true },
        '-=.2'
      )
      .add('logoShift')
      .to(
        logo,
        {
          x: 0,
          y: 0,
          scale: 1,
          duration: 0.95,
          ease: 'expo.inOut',
          force3D: true,
        },
        'logoShift'
      )
      .add('maskOut', '-=.25')
      .add('transitionFinished', '-=.45')
      .to(
        globalMask,
        { autoAlpha: 0, duration: 0.3, ease: 'sine.out', force3D: true },
        'maskOut'
      )
      .add(() => {
        GlobalConfig.transitionFinished = true;
      }, 'transitionFinished')
      .to(
        initiallyHiddenEls,
        { autoAlpha: 1, duration: 0.3, ease: 'sine.out', force3D: true },
        'maskOut'
      )
      .set(header, { zIndex: 40 }, '+=.2');

    gsap.set(logo, { opacity: 1 });

    gsap.delayedCall(0.5, () => {
      timeline.play();
    });
  });

  useScrollBasedAnims();

  // Resolving hydration error with wagmi and wallet connection
  // const [hasMounted, setHasMounted] = useState(false);
  //
  // useEffect(() => {
  //   setHasMounted(true);
  // }, []);
  //
  // if (!hasMounted) return null;
  const { route } = useRouter();
  const maskDark =
    route === '/' || route === '/about' || route === '/401' || route === '/404';
  return (
    <ApolloProvider client={apolloClient}>
      <NextNProgress
        color="#0A0A16"
        startPosition={0.3}
        stopDelayMs={200}
        height={3}
        showOnShallow={true}
        options={{ showSpinner: false }}
      />
      <div
        className={`${
          maskDark ? 'bg-brandBlue' : 'bg-white'
        } global-mask background fixed left-0 top-0 z-30 h-full w-full`}
        id="global-mask"
      />
      <div className="flex min-h-screen flex-col text-white">
        <header id="header" className={`${initStyle.header} fixed z-40 w-full`}>
          <NavBar />
        </header>
        <main className="flex grow flex-col">{children}</main>
        <Footer />
        <ToastContainer />
      </div>
    </ApolloProvider>
  );
};

export default Layout;
