import React from 'react';
import CheckMark from '@/components/svg/Checkmark';

interface TitleVerified {
  title: any;
  verified?: boolean;
  theme?: TitleVerifiedTheme;
  onClick?: (event: any) => void;
}

export enum TitleVerifiedTheme {
  default = 'fill-primary-main text-black',
  dark = 'fill-white group-hover:fill-primary-main text-white group-hover:text-black',
  light = 'fill-primary-main group-hover:fill-white text-neutral-100 group-hover:text-white',
  white = 'text-white font-bold text-md',
  bold = 'fill-primary-main text-black font-bold',
}

const TitleVerified = ({
  title,
  verified = true,
  theme = TitleVerifiedTheme.default,
  onClick,
}: TitleVerified) => {
  return (
    <div className={`flex items-center ${theme}`} onClick={onClick}>
      <div className="truncate align-bottom" title={title}>
        <p className="overflow-hidden truncate">{title}</p>
      </div>
      {verified && (
        <div className="pl-1">
          <CheckMark classes={theme} />
        </div>
      )}
    </div>
  );
};

export default TitleVerified;
