import { AnimatePresence, motion } from 'framer-motion';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import AnimatedCheck from '@/components/svg/AnimatedCheck';
import type { CopyTheme } from '@/components/svg/Copy';
import Copy from '@/components/svg/Copy';
import { copyTextToClipboard } from '@/utilities/functions';

interface CopyClipboardAnimationProps {
  clipBoardData: string;
  theme?: CopyTheme;
}

export interface CopyClipboardRef {
  clickHandler: (text: string) => void;
}

// eslint-disable-next-line react/display-name
export const CopyClipboardAnimation = forwardRef(
  ({ clipBoardData, theme }: CopyClipboardAnimationProps, ref: any) => {
    const [showSecond, setShowSecond] = useState(false);

    const clickHandler = useCallback(() => {
      void copyTextToClipboard(clipBoardData);
      setShowSecond(true);
    }, [clipBoardData]);

    useEffect(() => {
      if (ref) {
        ref.current = {
          clickHandler,
        };
      }
    }, [clickHandler, ref]);

    return (
      <div>
        <AnimatePresence>
          {!showSecond && (
            <motion.button
              initial={{ opacity: 1 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              onClick={clickHandler}
              className="flex"
            >
              <Copy theme={theme} width={'18'} height={'18'} />
            </motion.button>
          )}
          {showSecond && (
            <motion.div
              initial={{ opacity: 1 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 2 }}
              onAnimationComplete={() => setShowSecond(false)}
            >
              <AnimatedCheck strokeColor={'#50CD89'} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  }
);
