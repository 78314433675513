import React from 'react';
import type { ReactNode } from 'react';

interface Props {
  children: ReactNode | ReactNode[];
  classes?: string;
}

const PageContainer = ({ children, classes = '' }: Props) => {
  return (
    <div
      className={`container mx-auto mb-12 flex w-full flex-col justify-center px-4 pt-[92px] md:pt-[168px] ${classes}`}
    >
      {children}
    </div>
  );
};

export default PageContainer;
