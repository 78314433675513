import { useSession } from 'next-auth/react';
import { useEffect, useState } from 'react';
import { createApolloClient } from '@/utilities/createApolloClient';

export default function useApolloClientWithSession() {
  const { data: session } = useSession();
  const [apolloClient, setApolloClient] = useState(createApolloClient());

  useEffect(() => {
    if (session && session.token.error !== 'VerifyRefreshAccessTokenError') {
      setApolloClient(
        createApolloClient({
          authToken: session.token.accessToken,
        })
      );
    }
  }, [session]); // Add dependencies here

  return apolloClient;
}
