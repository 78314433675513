// Logic taken from: https://github.com/gpxl-dev/truncate-eth-address

import crypto from 'crypto';
import { Network } from 'alchemy-sdk';
import { compareDesc, isAfter, isBefore } from 'date-fns';
import { toChecksumAddress } from 'ethereum-checksum-address';
import { ERROR_CODES } from '@/constants/constants';

/**
 * Truncates an ethereum address to the format 0x0000…0000
 * @param address Full address to truncate
 * @returns Truncated address
 */
export const truncateAddress = (address: string) => {
  if (!address) return address;

  // Captures 0x + 4 characters, then the last 4 characters.
  const truncateRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;

  const match = address.match(truncateRegex);
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export async function copyTextToClipboard(text: string) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
}

export const getSecureRandomString = (length: number): string => {
  if (length <= 0) {
    throw new Error(ERROR_CODES.LENGTH_MUST_BE_POSITIVE);
  }

  const array = new Uint8Array(Math.ceil(length / 2));
  crypto.getRandomValues(array);
  return Array.from(array)
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('')
    .slice(0, length);
};

export const goToTopOfPage = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const raise = (error: string): never => {
  throw new Error(error);
};

export const cleanAddress = (address: string) => {
  try {
    return toChecksumAddress(address);
  } catch (error) {
    return undefined;
  }
};

export const getAlchemyNetwork = (networkId: number): Network => {
  switch (networkId) {
    case 1:
      return Network.ETH_MAINNET;
    case 5:
      return Network.ETH_GOERLI;
    case 137:
      return Network.MATIC_MAINNET;
    default:
      return Network.ETH_MAINNET;
  }
};

export const getOpenseaNetworkName = (networkId: number): string => {
  switch(networkId) {
    case 1:
      return "ethereum";
    case 5:
      return "";
    case 137:
      return "matic";
    default:
      return "ethereum";
  }
}

export const compareAddress = (address1: string, address2: string) => {
  return cleanAddress(address1) === cleanAddress(address2);
};

interface Deal {
  id: number;
  bidding_expiration: string;
}

export const sortDeals = (deals: Deal[]) => {
  const currentTime = new Date();
  const upcomingDeals = deals.filter((deal) =>
    isAfter(new Date(deal.bidding_expiration), currentTime)
  );
  const pastDeals = deals.filter((deal) =>
    isBefore(new Date(deal.bidding_expiration), currentTime)
  );
  pastDeals.sort((a, b) =>
    compareDesc(new Date(b.bidding_expiration), new Date(a.bidding_expiration))
  );
  return [...upcomingDeals, ...pastDeals];
};
