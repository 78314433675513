import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { apolloURI } from '@/constants/constants';

interface Props {
  authToken?: null | string;
}

export const createApolloClient = ({ authToken }: Props = {}) => {
  // User Client
  if (authToken) {
    return new ApolloClient({
      link: new HttpLink({
        uri: apolloURI,
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }),
      cache: new InMemoryCache(),
    });
  }
  // Public Client
  else {
    return new ApolloClient({
      link: new HttpLink({
        uri: apolloURI,
      }),
      cache: new InMemoryCache(),
    });
  }
};

export const createAdminClient = () => {
  return new ApolloClient({
    link: new HttpLink({
      uri: apolloURI,
      headers: {
        'content-type': 'application/json',
        'X-Hasura-Admin-Secret': process.env
          .HASURA_GRAPHQL_ADMIN_SECRET as string,
      },
    }),
    cache: new InMemoryCache(),
  });
};

export const createBackendOnlyClient = (walletAddress: string) => {
  return new ApolloClient({
    link: new HttpLink({
      uri: apolloURI,
      headers: {
        'content-type': 'application/json',
        'X-Hasura-Admin-Secret': process.env
          .HASURA_GRAPHQL_ADMIN_SECRET as string,
        'x-hasura-use-backend-only-permissions': 'true',
        'x-hasura-role': 'user',
        'x-hasura-wallet-address': walletAddress,
      },
    }),
    cache: new InMemoryCache(),
  });
};
