import { signOut, useSession } from 'next-auth/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount, useDisconnect, useEnsName } from 'wagmi';
import ConnectDrawer from '@/components/UI/drawer/drawers/ConnectDrawer';
import Avatar, { AvatarSize } from '@/components/cards/Avatar';

import fallbackImage from '@/public/img/fallback.jpg';
import {
  loadInitialState,
  logout,
  updateAddress,
  updateAuthState,
} from '@/store/accountSlice';
import { AuthState } from '@/types/Account';
import {
  useGetUserInfoByPkQuery,
  UsersRole_Enum,
} from '@/utilities/__generated__/graphql';
import { BucketType, createBucketURL } from '@/utilities/createBucketUrl';
import { truncateAddress } from '@/utilities/functions';
import { loadFromLocalStorage } from '@/utilities/localstorage';
import Button, { ButtonSize, ButtonType } from './UI/buttons/Button';
import AuthenticatedDrawer from './UI/drawer/drawers/AuthenticatedDrawer';

interface WalletConnectProps {
  setDrawerContent: (view: any) => void;
  setDrawerOpen: (open: boolean) => void;
  role: UsersRole_Enum | undefined;
}

const WalletConnect = ({
  setDrawerContent,
  setDrawerOpen,
  role,
}: WalletConnectProps) => {
  const authState: AuthState = useSelector(
    (state: any) => state.account.authState
  );

  const prevAddress: string = useSelector(
    (state: any) => state.account.address
  );

  const loaded: boolean = useSelector((state: any) => state.account.loaded);

  const dispatch = useDispatch();

  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  // Setting to default Img, in case of no role is sent as a prop
  const [avatarImage, setAvatarImage] = useState<string>(fallbackImage.src);
  const { data: ensName } = useEnsName({ address: address, chainId: 1 });

  const { data: session, status } = useSession();

  const { data: pageData } = useGetUserInfoByPkQuery({
    variables: {
      wallet_address: address as string,
    },
    onError: () => {
      // TODO: handle this with a refresh before presenting the error for expired JWT
      // apolloErrorHandler(error, dispatch);
    },
    skip: !address,
  });

  const getButtonText = useCallback(() => {
    switch (authState) {
      case AuthState.disconnected:
        return 'Login';
      case AuthState.authorized:
        if (session?.token.username) {
          return session?.token.username;
        } else if (ensName) {
          return ensName.toString();
        } else {
          return address ?? '';
        }
    }
  }, [authState, session?.token.username, ensName, address]);

  useEffect(() => {
    const disconnectWallet = async () => {
      disconnect();
      await signOut({ redirect: false });
      dispatch(logout(null));
      setDrawerOpen(false);
      setDrawerContent(
        ConnectDrawer({
          buttonText: truncateAddress(getButtonText()),
        })
      );
    };

    const closeDrawer = () => {
      setDrawerOpen(false);
    };

    switch (authState) {
      case AuthState.disconnected:
        setDrawerContent(
          ConnectDrawer({
            buttonText: truncateAddress(getButtonText()),
            closeDrawer,
          })
        );
        break;
      case AuthState.authorized:
        // TODO: figure out how to ensure this is only shown once
        // dispatch(
        //   addToast({
        //     id: uuidv4(),
        //     title: 'Wallet Connected',
        //     type: ToastType.success,
        //   } as ToastModel)
        // );

        setDrawerContent(
          AuthenticatedDrawer({
            address: address ?? '',
            disconnectWallet,
            closeDrawer,
            role: session?.token.role,
            username: session?.token.username,
            displayName: pageData?.Users_by_pk?.name ?? ensName,
            updatedAt: pageData?.Users_by_pk?.updated_at ?? '',
          })
        );
        break;
    }
  }, [
    authState,
    address,
    getButtonText,
    setDrawerContent,
    setDrawerOpen,
    disconnect,
    dispatch,
    session,
    pageData?.Users_by_pk?.name,
    ensName,
    pageData?.Users_by_pk?.updated_at,
  ]);

  useEffect(() => {
    const connected = address && isConnected;
    if (
      (connected && prevAddress !== address && loaded) ||
      !!session?.token.error
    ) {
      void signOut({ redirect: false });
    }

    dispatch(
      updateAuthState(
        status === 'authenticated' && connected
          ? AuthState.authorized
          : AuthState.disconnected
      )
    );
    if (connected) {
      dispatch(updateAddress(address));
    }
  }, [address, dispatch, isConnected, loaded, prevAddress, session, status]);

  useEffect(() => {
    const storage = loadFromLocalStorage();
    const state = storage?.address ? storage : { jwt: storage?.jwt };
    dispatch(loadInitialState(state));
  }, [dispatch]);

  const authButtonHandler = () => {
    setDrawerOpen(true);
  };

  useEffect(() => {
    let image: string;
    switch (role) {
      case UsersRole_Enum.Brand:
        image = createBucketURL(
          BucketType.Brand.toLowerCase(),
          session?.token.username,
          pageData?.Users_by_pk?.updated_at
        );
        setAvatarImage(image);
        break;
      case UsersRole_Enum.User:
        image = createBucketURL(
          BucketType.User.toLowerCase(),
          address,
          pageData?.Users_by_pk?.updated_at
        );
        setAvatarImage(image);
        break;
    }
  }, [
    role,
    address,
    session?.token.username,
    pageData?.Users_by_pk?.updated_at,
  ]);

  const connectButton = () => (
    <div
      onClick={authButtonHandler}
      className="hidden cursor-pointer flex-row place-content-center md:flex"
    >
      {authState === AuthState.authorized ? (
        <div className="relative z-10 translate-x-[28px] rounded-full">
          {/* TODO: add user image to store and display in avatar src */}
          <Avatar src={avatarImage} address={address} size={AvatarSize.sm} />
        </div>
      ) : null}
      <div className="flex">
        {authState === AuthState.disconnected ? (
          <Button size={ButtonSize.primary} type={ButtonType.primary}>
            {getButtonText()}
          </Button>
        ) : (
          <div
            className={
              'place-self-center rounded-[5px] bg-white py-2 pl-[44px] pr-4 text-neutral-100 hover:bg-primary-surface active:bg-primary-main'
            }
          >
            <p>{truncateAddress(getButtonText())}</p>
          </div>
        )}
      </div>
    </div>
  );

  return <>{connectButton()}</>;
};

export default WalletConnect;
