import React from 'react';

import { IoMdClose } from 'react-icons/io';

import Button, { ButtonSize, ButtonType } from './Button';

interface CloseButtonProps {
  onClick: () => void;
}

const CloseButton = ({ onClick }: CloseButtonProps) => {
  return (
    <div className="hover:bg-gray-1 rounded-full hover:cursor-pointer">
      <Button type={ButtonType.primary} size={ButtonSize.sm} onClick={onClick}>
        <IoMdClose />
      </Button>
    </div>
  );
};

export default CloseButton;
