import Link from 'next/link';
import React from 'react';
import { MdCreate } from 'react-icons/md';
import { RiMoneyDollarBoxLine } from 'react-icons/ri';
import type { ForwardedRef } from 'react';

interface InfoProps {
  strokeColor?: string;
}

const Info = ({ strokeColor = '#8490FF' }: InfoProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.25 9.25L9.291 9.23C9.41923 9.16594 9.56313 9.13997 9.70567 9.15516C9.8482 9.17035 9.9834 9.22606 10.0952 9.31571C10.2071 9.40536 10.2909 9.52518 10.3368 9.66099C10.3826 9.79679 10.3886 9.9429 10.354 10.082L9.646 12.918C9.61114 13.0572 9.61694 13.2034 9.66269 13.3394C9.70845 13.4754 9.79224 13.5954 9.90414 13.6852C10.016 13.775 10.1513 13.8308 10.294 13.846C10.4367 13.8612 10.5807 13.8352 10.709 13.771L10.75 13.75M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10ZM10 6.25H10.008V6.258H10V6.25Z"
      stroke={strokeColor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Brand = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4996 19V11.5C12.4996 11.3011 12.5786 11.1103 12.7193 10.9697C12.8599 10.829 13.0507 10.75 13.2496 10.75H16.2496C16.4485 10.75 16.6393 10.829 16.78 10.9697C16.9206 11.1103 16.9996 11.3011 16.9996 11.5V19M12.4996 19H1.35962M12.4996 19H16.9996M16.9996 19H20.6396M19.2496 19V7.349C19.6482 7.11891 19.9885 6.80019 20.2442 6.41751C20.4999 6.03484 20.6641 5.59846 20.7242 5.14215C20.7842 4.68584 20.7384 4.22184 20.5904 3.78605C20.4424 3.35026 20.1961 2.95436 19.8706 2.629L18.6806 1.44C18.3996 1.15862 18.0183 1.00035 17.6206 1H4.37762C3.97993 1.00035 3.59866 1.15862 3.31762 1.44L2.12862 2.628C1.80386 2.95374 1.55824 3.3497 1.41069 3.78537C1.26315 4.22104 1.21762 4.68477 1.27762 5.14082C1.33762 5.59686 1.50155 6.03304 1.75676 6.41571C2.01197 6.79839 2.35166 7.11735 2.74962 7.348M2.74962 18.999V7.35C3.34965 7.69598 4.05087 7.82376 4.73437 7.71167C5.41786 7.59958 6.04154 7.25451 6.49962 6.735C6.78092 7.05445 7.12712 7.31022 7.51512 7.48525C7.90311 7.66028 8.32398 7.75054 8.74962 7.75C9.64562 7.75 10.4496 7.357 10.9996 6.734C11.2808 7.05363 11.627 7.30959 12.015 7.48479C12.403 7.66 12.8239 7.75041 13.2496 7.75C14.1456 7.75 14.9496 7.357 15.4996 6.734C15.9578 7.25335 16.5816 7.59822 17.2651 7.71013C17.9486 7.82204 18.6497 7.6941 19.2496 7.348M5.74962 15.999H9.49962C9.69854 15.999 9.8893 15.92 10.03 15.7793C10.1706 15.6387 10.2496 15.4479 10.2496 15.249V11.5C10.2496 11.3011 10.1706 11.1103 10.03 10.9697C9.8893 10.829 9.69854 10.75 9.49962 10.75H5.74962C5.55071 10.75 5.35995 10.829 5.21929 10.9697C5.07864 11.1103 4.99962 11.3011 4.99962 11.5V15.25C4.99962 15.665 5.33562 15.999 5.74962 15.999Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Account = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9815 16.7247C15.2828 15.7996 14.3788 15.0493 13.3407 14.5331C12.3026 14.0168 11.1589 13.7487 9.99953 13.7497C8.84017 13.7487 7.69641 14.0168 6.65834 14.5331C5.62028 15.0493 4.71627 15.7996 4.01753 16.7247M15.9815 16.7247C17.345 15.5119 18.3066 13.9133 18.7407 12.1408C19.1748 10.3683 19.0598 8.50579 18.411 6.80018C17.7622 5.09457 16.6103 3.62648 15.1079 2.5906C13.6056 1.55472 11.8239 1 9.99903 1C8.17419 1 6.39246 1.55472 4.89013 2.5906C3.3878 3.62648 2.23585 5.09457 1.58706 6.80018C0.938265 8.50579 0.823276 10.3683 1.25734 12.1408C1.69141 13.9133 2.65403 15.5119 4.01753 16.7247M15.9815 16.7247C14.3355 18.1929 12.2052 19.0028 9.99953 18.9997C7.79355 19.0031 5.66376 18.1931 4.01753 16.7247M12.9995 7.74971C12.9995 8.54535 12.6835 9.30842 12.1208 9.87103C11.5582 10.4336 10.7952 10.7497 9.99953 10.7497C9.20388 10.7497 8.44081 10.4336 7.8782 9.87103C7.3156 9.30842 6.99953 8.54535 6.99953 7.74971C6.99953 6.95406 7.3156 6.19099 7.8782 5.62838C8.44081 5.06578 9.20388 4.74971 9.99953 4.74971C10.7952 4.74971 11.5582 5.06578 12.1208 5.62838C12.6835 6.19099 12.9995 6.95406 12.9995 7.74971Z"
      stroke="#0A0A16"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ButtonIcon = {
  Info: ({ strokeColor }: { strokeColor?: string }) => (
    <Info strokeColor={strokeColor} />
  ),
  Brand: <Brand />,
  Account: <Account />,
  Create: <MdCreate size={24} />,
  Money: <RiMoneyDollarBoxLine size={24} />,
};

enum Theme {
  Primary = 'primary',
  Neutral = 'neutral',
}

type ThemeType = Theme;

export const Chevron = ({ themeType }: { themeType: ThemeType }) => {
  const strokeColor = themeType === Theme.Primary ? '#8490FF' : '#605e62';
  return (
    <svg
      width="10"
      height="18"
      viewBox="0 0 10 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.25 1.5L8.75 9L1.25 16.5"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const themes = {
  [Theme.Primary]: {
    background: 'bg-primary-surface',
    text: 'text-primary-main',
  },
  [Theme.Neutral]: {
    background:
      'bg-neutral-10 hover:bg-primary-surface pressed:bg-primary-pressed',
    text: 'text-neutral-100',
  },
};

interface DrawerButtonProps {
  title: string;
  link: string;
  buttonIcon?: JSX.Element;
  action?: () => void;
  theme: ThemeType;
  classes?: string;
}

const DrawerButton = React.forwardRef<HTMLButtonElement, DrawerButtonProps>(
  (
    {
      title,
      buttonIcon = ButtonIcon.Info({}),
      link,
      action,
      theme = Theme.Primary,
      classes = '',
    }: DrawerButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const themeClassNames = themes[theme];

    return (
      <Link href={link} passHref>
        <button
          className={`flex w-full cursor-pointer items-center justify-between rounded-md ${themeClassNames.background} px-4 py-3 ${classes}`}
          onClick={action}
          ref={ref}
        >
          <div className="flex items-center">
            {buttonIcon}
            <div className={`flex-shrink pl-2 ${themeClassNames.text}`}>
              {title}
            </div>
          </div>
          <Chevron themeType={theme} />
        </button>
      </Link>
    );
  }
);

DrawerButton.displayName = 'DrawerButton';

export { Theme };
export default DrawerButton;
