import type { Collections } from '@/models/Collections';

import type { NftsByCollection } from '@/models/NftsByCollection';
import { getAlchemyNetwork } from '@/utilities/functions';

export const fetchNfts = async (
  vault: string,
  collections: Collections[],
  chainId: number
): Promise<NftsByCollection[]> => {
  // Clean up collections to include only the necessary properties
  const cleanedCollections = collections.map(
    ({ nft_contract_address, name }) => ({
      nft_contract_address,
      name,
    })
  );

  // Serialize the cleaned collections array to a JSON string
  const collectionsJson = encodeURIComponent(
    JSON.stringify(cleanedCollections)
  );

  // Construct the query string with the necessary parameters
  const queryString = `?address=${vault.toLowerCase()}&collections=${collectionsJson}&network=${getAlchemyNetwork(
    chainId
  )}`;

  // Make a GET request to the backend with the parameters in the query string
  const response = await fetch(`/api/auth/getOwnedNfts${queryString}`);

  return await response.json();
};
