import { createSlice } from '@reduxjs/toolkit';

import { TOKEN_NAME } from '@/constants/constants';
import { AuthState } from '@/types/Account';
import { saveToLocalStorage } from '@/utilities/localstorage';
import type { PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  address?: string | null;
  authState?: AuthState;
  jwt?: string | null;
  loaded: boolean;
}

const initialState = {
  address: null,
  authState: AuthState.disconnected,
  jwt: null,
  loaded: false,
};

const accountReducer = createSlice({
  name: 'account',
  initialState,
  reducers: {
    updateAddress(state: InitialState, action: PayloadAction<string>) {
      state.address = action.payload;
      saveToLocalStorage(TOKEN_NAME, state);
    },
    updateAuthState(state: InitialState, action: PayloadAction<AuthState>) {
      state.authState = action.payload;
      saveToLocalStorage(TOKEN_NAME, state);
    },
    updateJwt(state: InitialState, action: PayloadAction<string>) {
      state.jwt = action.payload;

      if (action.payload === null) {
        state.address = null;
        state.authState = AuthState.disconnected;
      }

      saveToLocalStorage(TOKEN_NAME, state);
    },
    loadInitialState(state: any, action: PayloadAction<any>) {
      state.address = action.payload.address ?? null;
      state.authState = action.payload.authState ?? AuthState.disconnected;
      state.jwt = action.payload.jwt ?? null;
      state.loaded = true;
      saveToLocalStorage(TOKEN_NAME, state);
    },
    logout(state: any, action: PayloadAction<any>) {
      state.jwt = action.payload;
      state.address = null;
      state.authState = AuthState.disconnected;
      saveToLocalStorage(TOKEN_NAME, state);
    },
  },
});

export const {
  loadInitialState,
  logout,
  updateAddress,
  updateAuthState,
  updateJwt,
} = accountReducer.actions;

export default accountReducer.reducer;
