import { useRouter } from 'next/router';
import React from 'react';
import TitleVerified from '@/components/cards/TitleVerified';
import { CHAIN_ID } from '@/constants/constants';
import useNftMeta from '@/hooks/useNftMeta';
import type { AvatarInfoProps } from '@/models/AvatarInfoProps';
import type { Bids } from '@/utilities/__generated__/graphql';

import { cleanAddress, getAlchemyNetwork } from '@/utilities/functions';
import Avatar, { AvatarSize } from './Avatar';

export const NFTWrapper = ({ nft }: { nft: Bids }) => {
  const network = getAlchemyNetwork(CHAIN_ID);

  const { nftThumbnail } = useNftMeta({
    collectionAddress: cleanAddress(nft.nft_contract_address),
    tokenId: nft.token_id,
    network: network,
  });

  // TODO: Find a way to make this accept a generic {children} param and pass nftImage
  return (
    <AvatarInfo
      image={nftThumbnail}
      size={AvatarSize.sm}
      title={nft.Collection.name ?? ''}
      verified={true}
      primaryInfo={`#${nft.token_id}`}
    />
  );
};

const AvatarInfo = ({
  count,
  image,
  imageLink,
  textLink,
  alt,
  size,
  shape,
  title,
  verified,
  primaryInfo,
  secondaryInfo,
  address,
  theme,
  contentClasses = 'text-neutral-50',
}: AvatarInfoProps) => {
  const router = useRouter();

  const conditionallyRoute = (link?: string) => (event: any) => {
    // Prevent event propagation to the parent row click event
    if (link) {
      event.preventDefault();
      void router.push(link);
    }
  };

  return (
    <div className={`flex items-center ${theme}`}>
      <div className="h-full">
        <Avatar
          unoptimized={true}
          shape={shape}
          count={count}
          address={address ? cleanAddress(address) : ''}
          size={size}
          src={image}
          alt={alt}
          link={imageLink}
          onClick={conditionallyRoute(imageLink)}
        />
      </div>
      <div className={`grid grid-cols-1 pl-4 text-neutral-50 ${theme}`}>
        <TitleVerified
          title={title}
          verified={verified}
          theme={theme}
          onClick={conditionallyRoute(textLink)}
        />
        {primaryInfo ? (
          <div className="flex-shrink-0">
            <p className={`truncate text-left ${contentClasses}`}>
              {primaryInfo}
            </p>
          </div>
        ) : undefined}
        {secondaryInfo ? (
          <div className="flex-shrink-0">
            <p className={`truncate text-left ${contentClasses}`}>
              {secondaryInfo}
            </p>
          </div>
        ) : undefined}
      </div>
    </div>
  );
};

export default AvatarInfo;
