import {
  connectorsForWallets,
  getDefaultWallets,
} from '@rainbow-me/rainbowkit';

import { getCsrfToken, signIn } from 'next-auth/react';
import { SiweMessage } from 'siwe';
import { configureChains, createConfig } from 'wagmi';
import { mainnet, polygon } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';

import { cleanAddress } from '@/utilities/functions';
import { magicConnector } from './connectors';

// TODO: can people access the alchemy api key from front end here?
export const { chains, publicClient, webSocketPublicClient } = configureChains(
  [mainnet, polygon],
  [
    // alchemyProvider({ apiKey: process.env.ALCHEMY_API_KEY ?? '' }),
    publicProvider(),
  ]
);

const { wallets } = getDefaultWallets({
  appName: 'OverpassIP',
  projectId: process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID ?? '',
  chains,
});

const connectors = connectorsForWallets([...wallets]);
const connectorList = connectors();
connectorList.push(magicConnector);

export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: connectorList,
  publicClient,
  webSocketPublicClient,
});

export const walletLogin = (
  address: string,
  isConnected: boolean,
  activeConnector: any,
  signMessageAsync: any,
  setLoading: any,
  connect: any
) => {
  if (
    (activeConnector === undefined || activeConnector === null) &&
    isConnected
  ) {
    const serializedState = localStorage.getItem('wagmi.wallet');
    if (serializedState !== null) {
      const wallet = JSON.parse(serializedState);
      if (wallet === 'magic' || wallet === 'Magic') {
        connect();
        return;
      }
    }
  }

  if (
    !!address &&
    isConnected &&
    (activeConnector as any) === (magicConnector as any)
  ) {
    if (setLoading) {
      setLoading(true);
    }
    return getCsrfToken()
      .then((nonce) => {
        const message = new SiweMessage({
          domain: window.location.host,
          address: cleanAddress(address),
          statement: 'Sign in with Ethereum to the app.',
          uri: window.location.origin,
          version: '1',
          chainId: 137,
          nonce: nonce,
        });
        return {
          message,
          signaturePromise: signMessageAsync({
            message: message.prepareMessage(),
          }),
        };
      })
      .then(({ signaturePromise, message }) =>
        signaturePromise.then((signature: string) => {
          signIn('credentials', {
            message: JSON.stringify(message),
            redirect: false,
            signature,
          });
          return null;
        })
      )
      .catch((error) => {
        if (setLoading) {
          setLoading(false);
        }
        throw error;
      });
  } else {
    return Promise.reject(null);
  }
};
