import '@fortawesome/fontawesome-svg-core/styles.css';
import '@rainbow-me/rainbowkit/styles.css';
import { config } from '@fortawesome/fontawesome-svg-core';

import '@mantine/core/styles.css';
import '@/styles/globals.scss';

import { createTheme, MantineProvider } from '@mantine/core';
import { PrismicPreview } from '@prismicio/next';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { RainbowKitSiweNextAuthProvider } from '@rainbow-me/rainbowkit-siwe-next-auth';
import * as Sentry from '@sentry/react';
import { SessionProvider } from 'next-auth/react';
import React from 'react';
import { Provider } from 'react-redux';
import { polygon } from 'viem/chains';
import { WagmiConfig } from 'wagmi';
import ErrorComponent from '@/components/common/ErrorComponent';
import Layout from '@/components/layout/Layout';
import { repositoryName } from '@/prismicio';
import { store } from '@/store/appStore';
import { chains, wagmiConfig } from '@/utilities/wallet';
import type { CSSVariablesResolver } from '@mantine/core';
import type { GetSiweMessageOptions } from '@rainbow-me/rainbowkit-siwe-next-auth';
import type { AppProps } from 'next/app';

config.autoAddCss = false;

const theme = createTheme({
  fontFamily: 'Satoshi',
});

const resolver: CSSVariablesResolver = () => ({
  variables: {
    '--mantine-hero-height': '0px',
    '--mantine-primary-color-filled': '#6A73CC',
  },
  light: {},
  dark: {},
});

const getSiweMessageOptions: GetSiweMessageOptions = () => ({
  statement: 'Connect your wallet to OverpassIP.',
});

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <Provider store={store}>
      <WagmiConfig config={wagmiConfig}>
        <SessionProvider
          session={pageProps.session}
          refetchInterval={0}
          refetchOnWindowFocus={false}
        >
          <RainbowKitSiweNextAuthProvider
            getSiweMessageOptions={getSiweMessageOptions}
          >
            <RainbowKitProvider chains={chains} initialChain={polygon}>
              <React.StrictMode>
                <MantineProvider theme={theme} cssVariablesResolver={resolver}>
                  <Layout>
                    <PrismicPreview repositoryName={repositoryName}>
                      <Sentry.ErrorBoundary
                        fallback={
                          <ErrorComponent
                            description="We’re sorry, but it looks like something went wrong."
                            title="Error"
                            type="500"
                          />
                        }
                      >
                        <Component {...pageProps} />
                      </Sentry.ErrorBoundary>
                    </PrismicPreview>
                  </Layout>
                </MantineProvider>
              </React.StrictMode>
            </RainbowKitProvider>
          </RainbowKitSiweNextAuthProvider>
        </SessionProvider>
      </WagmiConfig>
    </Provider>
  );
}

export default MyApp;
