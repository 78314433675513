import { motion } from 'framer-motion';
import * as React from 'react';

function AnimatedCheck({ strokeColor }: { strokeColor: string }) {
  return (
    <div className="flex h-[18px] w-[18px] self-center rounded-full border-2 border-neutral-50">
      <motion.svg
        width={22}
        height={22}
        viewBox="0 0 34 34"
        className={'translate-x-0.45 self-center'}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2392_1446)">
          <motion.path
            d="M9.35 17l5.1 5.1L25.5 11.05"
            initial={{
              pathLength: 0,
            }}
            animate={{
              pathLength: 1.2,
            }}
            transition={{
              delay: 0.2,
              duration: 0.5,
              ease: 'easeInOut',
            }}
            stroke={strokeColor}
            strokeWidth={2.5}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_2392_1446">
            <path fill="#fff" d="M0 0H34V34H0z" />
          </clipPath>
        </defs>
      </motion.svg>
    </div>
  );
}

export default AnimatedCheck;
