import React from 'react';
import Divider from '@/components/UI/Divider';

const DividerText = ({ text, classes }: { text: string; classes?: string }) => {
  return (
    <div
      className={`${classes} flex w-full items-center justify-center space-x-2`}
    >
      <Divider classes="mr-2" />
      <p>{text}</p>
      <Divider classes="ml-2" />
    </div>
  );
};
export default DividerText;
