import React from 'react';

interface Props {
  fill?: string;
  classes?: string;
}

const Hamburger = ({ classes, fill = 'fill-accent-main' }: Props) => {
  const className = `h-6 w-[30px] fill-current ${classes}`;
  return (
    <svg
      width="30"
      height="24"
      viewBox="0 0 30 24"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2"
        width="25"
        height="4"
        rx="2"
        className={`${fill} header-fill-el`}
      />
      <rect
        y="10"
        width="30"
        height="4"
        rx="2"
        className={`${fill} header-fill-el`}
      />
      <rect
        x="2"
        y="20"
        width="25"
        height="4"
        rx="2"
        className={`${fill} header-fill-el`}
      />
    </svg>
  );
};
export default Hamburger;
