import Link from 'next/link';
import React from 'react';
import Pill from '@/components/UI/Pill';
import Logo from '@/components/svg/Logo';

const Footer = () => {
  const linkStyle = 'cursor-pointer text-md text-primary-main hover:opacity-75';
  return (
    <div className="z-[1] w-full bg-neutral-100">
      <div className="container mx-auto grid-cols-3 items-start justify-start px-4 py-10 md:grid md:flex-row md:items-center">
        <div className="flex items-center md:order-1">
          <Link className="" href="/">
            <Logo width={'183px'} height={'29px'} fill={'#9B9CEE'} />
          </Link>
        </div>
        <div className="pt-10 md:order-3 md:flex md:pt-0">
          <ul className="flex list-none flex-row gap-x-4 space-y-2 md:ml-auto md:space-y-0">
            <li className="nav-item self-center">
              <Link className="flex py-2 " href="/terms">
                <span className={linkStyle}>Terms of Service</span>
              </Link>
            </li>
          </ul>
        </div>
        <div className="justify-start pt-8 text-md text-primary-main md:order-2 md:justify-center md:pt-0 md:text-center">
          <p>
            © {new Date().getFullYear()} OverpassIP Inc. <br />
            All Rights Reserved.
          </p>
          <Pill showDot={false} pulse={false}>
            <span className="bg-gradient-to-r from-accent-hover to-accent-focus bg-clip-text  uppercase text-transparent">
              Powered by P.P.T. 🐧
            </span>
          </Pill>
        </div>
      </div>
    </div>
  );
};
export default Footer;
