export const debounce = (
  fn: (...args: any[]) => void,
  ms: number
): ((...args: any[]) => void) => {
  let timer: NodeJS.Timeout | null;
  return (...args: any[]): void => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      timer = null;
      fn(...args);
    }, ms);
  };
};
