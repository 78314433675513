import { Network } from 'alchemy-sdk';
import { useEffect, useState } from 'react';

const useNftMeta = ({
  collectionAddress,
  tokenId,
  network = Network.ETH_MAINNET,
}: {
  collectionAddress?: string | undefined | null;
  tokenId?: string | undefined | null;
  network?: string | undefined | null;
} = {}) => {
  const [loading, setLoading] = useState(true);
  const [nftImage, setNftImage] = useState<string>();
  const [nftThumbnail, setNftThumbnail] = useState<string>();
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    if (!collectionAddress || !tokenId) {
      setLoading(false);
      return;
    }

    const fetchNftMeta = async () => {
      const settings = network;
      fetch(
        `/api/auth/getNftMeta?collectionAddress=${collectionAddress}&tokenId=${tokenId}&settings=${settings}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
        .then((res) => {
          if (res.status === 500) {
            setError(true);
          }
          if (res.status === 200) {
            res.json().then((meta: any) => {
              if (meta.media === undefined || meta.media === null) {
                setError(true);
              } else {
                setNftImage(meta?.media?.full);
                setNftThumbnail(meta?.media?.thumbnail);
                setLoading(false);
              }
            });
          }
        })
        .catch((err) => {
          setError(true);
          return Promise.reject(err);
        });
    };
    void fetchNftMeta();
  }, [collectionAddress, tokenId, network]);
  return {
    nftImage,
    nftThumbnail,
    loading,
    error,
  };
};
export default useNftMeta;
