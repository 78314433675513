import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { removeToast } from '@/store/toastSlice';
import Toast from './Toast';
import type { ToastModel } from './Toast';

const ToastContainer = () => {
  const dispatch = useDispatch();
  const toastState: ToastModel[] = useSelector(
    (state: any) => state.toasts.toasts
  );

  const [portalRef, setPortalRef] = useState<Element>();

  useEffect(() => {
    setPortalRef(document.getElementById('overlays') as Element);
  }, []);

  const closeToast = (id: string) => {
    dispatch(removeToast(id));
  };

  return (
    <>
      {portalRef != undefined &&
        createPortal(
          <div className="fixed bottom-5 right-5 z-[100] box-border space-y-2">
            {toastState.map((toast: ToastModel) => {
              return (
                <Toast
                  key={toast.id}
                  title={toast.title}
                  type={toast.type}
                  id={toast.id}
                  onClose={closeToast}
                />
              );
            })}
          </div>,
          portalRef
        )}
    </>
  );
};

export default ToastContainer;
