import React from 'react';

const Logo = React.forwardRef(({ fill, width, height }: any, ref: any) => {
  return (
    <svg
      ref={ref}
      height={height}
      width={width}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 16.9867V5.515L3.77878 1.66333H14.3087L18.1233 5.51222V16.9867L14.3087 20.8356H3.77878L0 16.9867ZM12.2859 17.1783L13.8133 15.6371V6.86184L12.2859 5.32061H5.83744L4.30996 6.86184V15.6399L5.83744 17.1811H12.2859V17.1783Z"
        fill={fill}
        className="char header-fill-el"
      />
      <path
        d="M19.208 1.66357H23.6721L28.4802 16.0233H28.6316L33.4782 1.66357H37.9423L30.7315 20.8358H26.3803L19.208 1.66357Z"
        fill={fill}
        className="char header-fill-el"
      />
      <path
        d="M39.0236 17.0257V5.51515L42.8023 1.66626H53.1424L56.9955 5.51515V12.677H43.3363V15.7567L44.7867 17.2201H51.3122L52.6855 15.7955V14.6403H56.9597V17.1035L53.258 20.8385H42.7638L39.0236 17.0257ZM52.6828 9.48068V6.82311L51.1938 5.28189H44.8225L43.3335 6.82311V9.48068H52.6828Z"
        fill={fill}
        className="char header-fill-el"
      />
      <path
        d="M60.0068 1.66357H64.0141V4.93486L67.1819 1.66357H72.4468V5.39861H68.1341L64.3195 9.32526V20.8358H60.0068V1.66357Z"
        fill={fill}
        className="char header-fill-el"
      />
      <path
        d="M74.4831 1.66333H78.4903V4.66524L81.504 1.66333H88.7918L92.6064 5.51222V16.9867L88.7918 20.8356H81.4655L78.7931 18.564V28.9971H74.4803V1.66333H74.4831ZM86.769 17.1033L88.2964 15.601V6.90071L86.769 5.39837H82.1893L78.7931 8.82516V14.1764L81.9994 17.1033H86.769Z"
        fill={fill}
        className="char header-fill-el"
      />
      <path
        d="M95.7879 17.4117V12.8686L99.1841 9.48068H108.99V6.6315L107.578 5.20691H101.587L100.175 6.6315V7.86448H95.9393V5.63179L99.8309 1.66626H109.332L113.223 5.63179V20.8385H109.293V17.8727L106.241 20.8385H99.1814L95.7852 17.4117H95.7879ZM105.822 17.4117L108.99 14.3321V12.7159H101.246L100.024 13.91V16.2593L101.168 17.4145H105.822V17.4117Z"
        fill={fill}
        className="char header-fill-el"
      />
      <path
        d="M116.215 17.2562V14.9068H120.45V16.1398L121.518 17.2562H128.005L129.073 16.1398V14.0599L127.967 12.9435H119.762L116.366 9.47784V5.16519L119.84 1.66064H129.417L132.929 5.24017V7.5895H128.693V6.35652L127.626 5.24017H121.672L120.605 6.35652V8.28096L121.711 9.39731H129.877L133.312 12.9019V17.3284L129.838 20.8329H119.727L116.218 17.2534L116.215 17.2562Z"
        fill={fill}
        className="char header-fill-el"
      />
      <path
        d="M135.535 17.2562V14.9068H139.771V16.1398L140.839 17.2562H147.326L148.394 16.1398V14.0599L147.287 12.9435H139.083L135.687 9.47784V5.16519L139.16 1.66064H148.738L152.25 5.24017V7.5895H148.014V6.35652L146.946 5.24017H140.993L139.925 6.35652V8.28096L141.032 9.39731H149.197L152.632 12.9019V17.3284L149.159 20.8329H139.047L135.538 17.2534L135.535 17.2562Z"
        fill={fill}
        className="char header-fill-el"
      />
      <path
        d="M182.381 20.7302L183 19.972L182.169 11.1912L182.838 2.39376L182.205 1.64953L182.131 1.56899L181.443 0.874749L181.167 0.62482L180.416 0L171.713 0.838648L162.994 0.163842L162.257 0.802547L162.177 0.877526L161.489 1.57177L161.241 1.84947L160.622 2.60758L161.453 11.3884L160.784 20.1859L161.417 20.9301L161.492 21.0106L162.18 21.7049L162.455 21.9548L163.206 22.5796L171.909 21.741L180.628 22.4158L181.365 21.7771L181.445 21.7021L182.133 21.0079L182.381 20.7302ZM180.482 11.63L179.899 19.2972L172.212 20.0387H171.477L163.875 19.45L163.14 11.6966V10.9524L163.724 3.28517L171.408 2.54371H172.146L179.744 3.13243L180.479 10.8858V11.63H180.482Z"
        fill={fill}
        className="char header-fill-el"
      />
      <path
        d="M167.893 7.66455H169.289V14.9152H167.893V7.66455Z"
        fill={fill}
        className={'tiny-char'}
      />
      <path
        d="M170.676 7.66455H175.181L176.279 8.78367V11.3218L175.17 12.4493H172.071V14.9152H170.676V7.66455ZM174.545 11.2691L174.906 10.9053V9.20577L174.545 8.84199H172.071V11.2663H174.545V11.2691Z"
        fill={fill}
        className={'tiny-char'}
      />
    </svg>
  );
});

Logo.displayName = 'Logo';
export default Logo;
