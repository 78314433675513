import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { CHAIN_ID } from '@/constants/constants';
import { useAppSelector } from '@/hooks/useApp';
import useDelegationByWallet from '@/hooks/useDelegationByWallet';
import type { Collections } from '@/models/Collections';
import type { NftsByCollection } from '@/models/NftsByCollection';
import { setDelegatedNftsStore } from '@/store/nftSlice';
import { useGetCollectionsQuery } from '@/utilities/__generated__/graphql';
import { fetchNfts } from '@/utilities/fetchNfts';
import { compareAddress } from '@/utilities/functions';

const useDelegatedNfts = (
  delegate: string,
  requestedCollections?: Collections[]
) => {
  const [delegatedNfts, setDelegatedNfts] = useState<NftsByCollection[]>([]);
  const [delegatedNftsBeforeFiltering, setDelegatedNftsBeforeFiltering] =
    useState<NftsByCollection[]>([]);
  const { data: collections } = useGetCollectionsQuery();
  const { delegations, loading: delegationsLoading } =
    useDelegationByWallet(delegate);

  const dispatch = useDispatch();
  const nftsDelegatedFromState = useAppSelector(
    (state) => state.userNFTs.delegatedNfts
  );

  useEffect(() => {
    // TODO the type returned from useGetCollectionsQuery doesn't match Collections[]
    // Types of property 'name' are incompatible.
    // Type 'string | null | undefined' is not assignable to type 'string'.
    // Type 'undefined' is not assignable to type 'string'
    if (!collections?.Collections) return;

    if (delegationsLoading) return;

    if (!delegations) return;

    if (!delegate) return;
    if (nftsDelegatedFromState[delegate]) {
      setDelegatedNftsBeforeFiltering(nftsDelegatedFromState[delegate]);
      return;
    }

    const fetches = delegations.map(async (delegation) => {
      if (delegation.type === 'NONE') {
        return null;
      }

      // If the delegation is not to the delegate we are looking for (outgoing delegation), skip it
      if (!compareAddress(delegation.to, delegate)) {
        return null;
      }

      // If the delegation is not to a collection we are looking for, skip it

      if (delegation.type !== 'ALL') {
        if (requestedCollections && delegation.contract) {
          const requestedCollection = requestedCollections.find((collection) =>
            compareAddress(collection.nft_contract_address, delegation.contract)
          );
          if (!requestedCollection) {
            return null;
          }
        }
      }

      const fetchedNfts = await fetchNfts(
        delegation.from,
        collections?.Collections as Collections[],
        CHAIN_ID
      );

      return { delegation, fetchedNfts };
    });

    Promise.all(fetches)
      .then((results) => {
        const nftCollectionToAdd: NftsByCollection[] = [];

        results.forEach((result) => {
          if (!result) {
            return;
          }

          const { delegation, fetchedNfts } = result;
          fetchedNfts.forEach((nftsByCollection) => {
            if (delegation.type === 'CONTRACT' || delegation.type === 'TOKEN') {
              // If the delegation is to a collection, filter out the nfts that are not in the delegation
              if (
                !compareAddress(delegation.contract, nftsByCollection.address)
              ) {
                return;
              }

              // If the delegation is to a token, filter out the nfts that are not in the delegation
              if (delegation.type === 'TOKEN') {
                const delegatedNft = nftsByCollection.nfts.find(
                  (nft) => nft.tokenId === delegation.tokenId?.toString()
                );
                if (!delegatedNft) {
                  return;
                }
                nftsByCollection.nfts = [delegatedNft];
              }
            }

            nftCollectionToAdd.push(nftsByCollection);
          });
        });
        dispatch(
          setDelegatedNftsStore({
            address: delegate,
            nfts: nftCollectionToAdd,
          })
        );
        setDelegatedNftsBeforeFiltering(nftCollectionToAdd);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }, [
    collections?.Collections,
    delegate,
    delegationsLoading,
    delegations,
    dispatch,
    nftsDelegatedFromState,
    requestedCollections,
  ]);

  useEffect(() => {
    let nftsAfterFiltering = delegatedNftsBeforeFiltering;
    if (requestedCollections) {
      nftsAfterFiltering = delegatedNftsBeforeFiltering.filter((nft) => {
        return requestedCollections.some((collection) => {
          return compareAddress(collection.nft_contract_address, nft.address);
        });
      });
    }
    setDelegatedNfts(nftsAfterFiltering);
  }, [requestedCollections, delegatedNftsBeforeFiltering]);

  return { delegatedNfts, loading: delegationsLoading };
};

export default useDelegatedNfts;
