import { useSession } from 'next-auth/react';
import React from 'react';
import LicensableNftTable from '@/components/Table/LicensableNftTable';
import Button, { ButtonSize, ButtonType } from '@/components/UI/buttons/Button';
import DrawerButton, {
  ButtonIcon,
  Theme,
} from '@/components/UI/buttons/DrawerButton';
import Avatar, { AvatarSize } from '@/components/cards/Avatar';
import WalletCard from '@/components/cards/WalletCard';
import { CopyTheme } from '@/components/svg/Copy';
import { Disconnect } from '@/components/svg/Disconnect';
import { UsersRole_Enum } from '@/utilities/__generated__/graphql';
import { BucketType, createBucketURL } from '@/utilities/createBucketUrl';
import WalletContainer from './WalletContainer';

interface AuthenticatedDrawerProps {
  address: string;
  disconnectWallet: () => void;
  closeDrawer?: () => void;
  role: UsersRole_Enum | undefined;
  username: string | undefined;
  displayName: string | null | undefined;
  updatedAt: Date | null | undefined;
}

const AuthenticatedDrawer = ({
  address,
  disconnectWallet,
  closeDrawer,
  role,
  username,
  displayName,
  updatedAt,
}: AuthenticatedDrawerProps) => {
  return (
    <WalletContainer>
      <div className="space-y-2">
        <DrawerAvatarGroup
          role={role}
          address={address}
          updatedAt={updatedAt}
        />
        <p className="font-gt-haptik text-heading-5">
          {displayName ? displayName : 'Unnamed'}
        </p>

        <div className="flex space-x-3 pb-10">
          <WalletCard copyTheme={CopyTheme.Dark} address={address} />
          <Button
            size={ButtonSize.sm}
            type={ButtonType.drawer}
            onClick={disconnectWallet}
          >
            <Disconnect />
          </Button>
        </div>
        {role === UsersRole_Enum.User || role === UsersRole_Enum.Brand ? (
          <>
            <DrawerButton
              title="My Profile"
              link={`/user/${address}`}
              buttonIcon={ButtonIcon.Account}
              action={closeDrawer}
              theme={Theme.Neutral}
            />
            <DrawerButton
              title="Opportunities"
              link={'/deals'}
              buttonIcon={ButtonIcon.Money}
              action={closeDrawer}
              theme={Theme.Neutral}
            />
            <DrawerButton
              title="About"
              link={'/about'}
              buttonIcon={ButtonIcon.Info({ strokeColor: '#000000' })}
              action={closeDrawer}
              theme={Theme.Neutral}
              classes="sm:flex md:hidden"
            />
          </>
        ) : null}
        {role === UsersRole_Enum.Brand && (
          <>
            <DrawerButton
              title="Brand Profile"
              link={`/brand/${username}`}
              buttonIcon={ButtonIcon.Brand}
              action={closeDrawer}
              theme={Theme.Neutral}
            />
            <DrawerButton
              title="Create Opportunity"
              link={'/deal/create'}
              buttonIcon={ButtonIcon.Create}
              action={closeDrawer}
              theme={Theme.Neutral}
            />
          </>
        )}
        <h1 className="pt-6 font-gt-haptik text-heading-6">
          Licensable Assets
        </h1>
        {address && <LicensableNftTable account={address} />}
      </div>
    </WalletContainer>
  );
};

export default AuthenticatedDrawer;

const DrawerAvatarGroup = ({ role, address, updatedAt }: any) => {
  const { data: session } = useSession();

  return (
    <div className="flex -space-x-4">
      {role === UsersRole_Enum.Brand && (
        <div className="z-10">
          <Avatar
            size={AvatarSize.sm}
            src={createBucketURL(
              BucketType.Brand.toLowerCase(),
              session?.token.username,
              updatedAt
            )}
          />
        </div>
      )}
      <Avatar
        size={AvatarSize.sm}
        address={address}
        src={createBucketURL(BucketType.User.toLowerCase(), address, updatedAt)}
      />
    </div>
  );
};
