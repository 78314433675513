import React, { useRef } from 'react';

import type { CopyClipboardRef } from '@/components/UI/CopyClipboardAnimation';
import { CopyClipboardAnimation } from '@/components/UI/CopyClipboardAnimation';
import type { CopyTheme } from '@/components/svg/Copy';
import { truncateAddress } from '@/utilities/functions';

interface WalletCardProps {
  address: string | null | undefined;
  copyTheme?: CopyTheme;
}

const WalletCard = ({ address, copyTheme }: WalletCardProps) => {
  const copyRef = useRef<CopyClipboardRef>();

  return (
    <div
      className="flex items-center justify-between rounded-lg bg-neutral-10 p-3 hover:cursor-pointer hover:bg-primary-surface"
      onClick={() => copyRef.current?.clickHandler(address ?? '')}
    >
      <p className="pr-6 text-md text-black">
        {truncateAddress(address ?? '')}
      </p>
      <CopyClipboardAnimation
        theme={copyTheme}
        clipBoardData={address ?? ''}
        ref={copyRef}
      />
    </div>
  );
};

export default WalletCard;
