import React, { Suspense } from 'react';
import overpassAnimation from '@/lotties/OverpassAnimation.json';
import PageContainer from './PageContainer';

const Lottie = React.lazy(() => import('lottie-react'));

const LoadingView = () => {
  return (
    <PageContainer classes="h-full grow items-center">
      <div className="h-28 w-28">
        <Suspense fallback={<div>Loading...</div>}>
          <Lottie animationData={overpassAnimation} loop={true} />
        </Suspense>
      </div>
    </PageContainer>
  );
};

export default LoadingView;
