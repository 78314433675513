import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { z } from 'zod';
import Button, { ButtonSize, ButtonType } from '@/components/UI/buttons/Button';
import type { ToastModel } from '@/components/UI/toast/Toast';
import { ToastType } from '@/components/UI/toast/Toast';
import LoadingView from '@/components/layout/LoadingView';
import PageContainer from '@/components/layout/PageContainer';
import useInput from '@/hooks/useInput';
import { addToast } from '@/store/toastSlice';
import { validateEmail } from '@/utilities/validateEmail';
import { validatePhone } from '@/utilities/validatePhone';
import type { NextPage } from 'next';

export enum JoinUserType {
  Brand = 'brand',
  Collection = 'collection',
  Negosh = 'negosh',
}

const Join: NextPage = () => {
  const router = useRouter();
  const [userType, setUserType] = useState<JoinUserType>();
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (
      router.query.userType === JoinUserType.Brand ||
      router.query.userType === JoinUserType.Collection ||
      router.query.userType === JoinUserType.Negosh
    ) {
      setUserType(router.query.userType as JoinUserType);
    }
  }, [router.query.userType]);

  const incompleteFormToast = () => {
    const fields: Record<string, boolean> = {
      'full name': !!fullNameIsValid,
      'company name': !!companyIsValid,
      email: !!emailIsValid,
      'phone number': !!phoneIsValid,
      'user type': !!userType,
    };

    const missingFields = Object.keys(fields).filter((field) => !fields[field]);

    let toastMessage;
    if (missingFields.length > 0) {
      toastMessage = `Please add your ${missingFields
        .join(', ')
        .replace(/,([^,]*)$/, ' and$1')}.`;
    }

    if (toastMessage) {
      dispatch(
        addToast({
          title: toastMessage,
          type: ToastType.warning,
        } as ToastModel)
      );
    }
  };

  const submitMutationHandler = () => {
    setLoading(true);
    const zapData = { fullName, email, phone, company, userType };
    try {
      fetch('/api/auth/joinBrand', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(zapData),
      }).then((r) => {
        if (r.ok) {
          setSubmitted(true);
          dispatch(
            addToast({
              title: 'Submitted',
              type: ToastType.success,
            } as ToastModel)
          );
        } else {
          setLoading(false);
        }
      });
    } catch (error) {
      dispatch(
        addToast({
          title: 'Error submitting join request',
          type: ToastType.error,
        } as ToastModel)
      );
      setLoading(false);
    } finally {
      setLoading(false);
    }
    resetFullNameInput();
    resetEmailInput();
    resetPhoneInput();
    resetCompanyInput();
  };

  const fullNameSchema = z.string().min(1);
  const validateFullName = (value: string) => {
    const isValueValid = fullNameSchema.safeParse(value).success;
    const isValueNotEmpty = value.trim() !== '';
    return isValueValid && isValueNotEmpty;
  };

  const companySchema = z.string().min(1);
  const validateCompany = (value: string) => {
    const isValueValid = companySchema.safeParse(value).success;
    const isValueNotEmpty = value.trim() !== '';
    return isValueValid && isValueNotEmpty;
  };

  const {
    inputValue: fullName,
    valueHandler: fullNameHandler,
    reset: resetFullNameInput,
    isInputInvalid: fullNameFormIsValid,
    isValueValid: fullNameIsValid,
    blurHandler: fullNameBlurHandler,
  } = useInput(validateFullName, '');

  const {
    inputValue: company,
    valueHandler: companyHandler,
    reset: resetCompanyInput,
    isInputInvalid: companyFormIsValid,
    isValueValid: companyIsValid,
    blurHandler: companyBlurHandler,
  } = useInput(validateCompany, '');

  const {
    inputValue: email,
    valueHandler: emailHandler,
    isValueValid: emailIsValid,
    isInputInvalid: emailFormIsValid,
    blurHandler: emailNameBlurHandler,
    reset: resetEmailInput,
  } = useInput(validateEmail, '');

  const {
    inputValue: phone,
    valueHandler: phoneHandler,
    isValueValid: phoneIsValid,
    isInputInvalid: phoneFormIsValid,
    blurHandler: phoneBlurHandler,
    reset: resetPhoneInput,
  } = useInput(validatePhone, '');

  return (
    <div className="flex w-full flex-col items-center justify-center">
      <Head>
        <title>Join OverpassIP</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="Join OverpassIP" />
        <meta
          property="og:image"
          content="/img/OverPassCover.png"
          key="ogimage"
        />
        <meta name="twitter:site" content="@OverpassIP" />
        <meta name="twitter:creator" content="@OverpassIP" />
        <meta name="twitter:title" content="Join OverpassIP" />
        <meta
          name="twitter:description"
          content="OverpassIP provides collections and brands accessible opportunities to partner with NFT holders and license their Web3 IP for your products. Join us in revolutionizing licensing for the future."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content="/img/OverPassCover.png" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <PageContainer>
        <div
          className={`container mt-[50px] max-w-[737px] self-center rounded-[25px] md:mt-0 ${
            submitted
              ? 'bg-accent-pressed bg-grid bg-cover bg-center bg-no-repeat'
              : 'bg-primary-surface'
          }`}
        >
          <div className="relative flex flex-col items-center self-center p-8 text-center">
            {loading ? (
              <LoadingView />
            ) : submitted ? (
              <>
                <div className="absolute top-[-60px]">
                  <Image
                    src="/img/CheckboxYes.png"
                    width="135"
                    height="148"
                    quality="100"
                    alt="3D checkbox"
                  />
                </div>
                <h2 className="mt-20 bg-gradient-to-r from-primary-focus to-primary-surface bg-clip-text font-gt-haptik text-heading-4 text-transparent">
                  Submission Successful!
                </h2>
                <p className="pb-8 pt-4 text-lg leading-6 md:w-[300px]">
                  Woohoo! Your application has been submitted and will be
                  reviewed soon.
                </p>
                <div className="mb-10 flex w-[80%] flex-col md:w-[40%]">
                  <Link
                    className="flex items-center py-2"
                    href={'/deals'}
                    passHref
                    legacyBehavior
                  >
                    <Button type={ButtonType.primary} size={ButtonSize.full}>
                      Explore Opportunities
                    </Button>
                  </Link>
                </div>
              </>
            ) : (
              <>
                <h1 className="font-gt-haptik text-heading-3-mobile font-bold text-neutral-100 md:text-heading-3">
                  Join OverpassIP
                </h1>
                <div className="font-normal mb-[35px] mt-3 max-w-[405px] text-left text-lg text-neutral-100 md:px-0">
                  OverpassIP provides collections and brands accessible
                  opportunities to partner with NFT holders and license their
                  Web3 IP for your products. Join us in revolutionizing
                  licensing for the future.
                </div>
                <div className=" w-full flex-col pb-4">
                  <input
                    className={`focus:shadow-outline h-[56px] w-full max-w-[405px] rounded-md border border-neutral-20 px-3 py-[16px] leading-tight hover:border-neutral-20 hover:bg-neutral-10 focus:border-primary-main focus:bg-primary-surface ${
                      fullNameFormIsValid
                        ? 'border border-error-main bg-error-surface'
                        : 'border border-neutral-20 text-neutral-100'
                    } ${
                      fullName.length ? 'text-neutral-100' : 'text-neutral-30'
                    }
                    `}
                    id="fullname"
                    value={fullName}
                    onChange={fullNameHandler}
                    onBlur={fullNameBlurHandler}
                    type="text"
                    placeholder="Full Name"
                  />
                  {fullNameFormIsValid ? (
                    <div className={'self-start text-sm text-error-main'}>
                      * Please enter your name
                    </div>
                  ) : null}
                </div>

                <div className=" w-full flex-col pb-4">
                  <input
                    className={`${
                      companyFormIsValid
                        ? 'border border-error-main bg-error-surface'
                        : 'border border-neutral-20 text-neutral-100'
                    } ${company.length ? 'text-neutral-100' : 'text-neutral-30'}
                focus:shadow-outline
                h-[56px] w-full max-w-[405px]
                rounded-md
                border
                border-neutral-20 px-3 
                py-[16px]
                leading-tight
                hover:border-neutral-20
                hover:bg-neutral-10
                focus:border-primary-main focus:bg-primary-surface
                `}
                    id="company"
                    value={company}
                    onChange={companyHandler}
                    onBlur={companyBlurHandler}
                    type="text"
                    placeholder="Company"
                  />
                  {companyFormIsValid ? (
                    <div className={'self-start text-sm text-error-main'}>
                      * Please enter your company or collection name
                    </div>
                  ) : null}
                </div>

                <div className=" w-full flex-col pb-[16px]">
                  <input
                    className={`${
                      emailFormIsValid
                        ? 'border border-error-main bg-error-surface'
                        : 'border border-neutral-20 text-neutral-100'
                    } ${email.length ? 'text-neutral-100' : 'text-neutral-30'}
                focus:shadow-outline 
                h-[56px] w-full
                max-w-[405px]
                rounded-md px-3 
                py-[16px]
                leading-tight
                hover:border-neutral-20
                hover:bg-neutral-10
                focus:border-primary-main focus:bg-primary-surface
                `}
                    id="email"
                    value={email}
                    onChange={emailHandler}
                    onBlur={emailNameBlurHandler}
                    type="text"
                    placeholder="Jon@mail.com"
                  />
                  {emailFormIsValid ? (
                    <div className={'self-start text-lg text-error-main'}>
                      * Please enter your email
                    </div>
                  ) : null}
                </div>
                <div className=" w-full flex-col">
                  <input
                    className={`shadow appearance-none ${
                      phoneFormIsValid
                        ? 'border border-error-main bg-error-surface'
                        : 'border border-neutral-20 text-neutral-100'
                    } ${
                      phone.length ? 'text-neutral-100' : 'text-neutral-30'
                    } focus:shadow-outline mb-3
                 h-[56px]
                  w-full 
                  max-w-[405px] rounded-md 
                  px-3 py-[16px]
                  leading-tight
                  hover:border-neutral-20
                  hover:bg-neutral-10 
                  focus:border-primary-main focus:bg-primary-surface
                  `}
                    id="phone"
                    type="phone"
                    value={phone}
                    onChange={phoneHandler}
                    onBlur={phoneBlurHandler}
                    placeholder="Phone"
                  />
                  {phoneFormIsValid ? (
                    <div className={'self-start text-lg text-error-main'}>
                      * Please enter your phone number
                    </div>
                  ) : null}
                  <div className="flex justify-center space-x-8">
                    <div className="mt-3 flex-col">
                      <label className="flex items-center">
                        <input
                          className="form-radio
          h-4
          w-4
          cursor-pointer
          appearance-none
          rounded-full
          border-[2px]
          border-primary-main
          bg-white
          bg-contain
          bg-center
          bg-no-repeat
          p-2
          align-top
          transition
          duration-200
          checked:border-primary-pressed
          checked:bg-primary-main
          focus:outline-none"
                          type="radio"
                          checked={userType === JoinUserType.Collection}
                          onChange={() => setUserType(JoinUserType.Collection)}
                        />
                        <span className="ml-2 text-neutral-50">Collection</span>
                      </label>
                    </div>
                    <div className="mt-3 flex-col ">
                      <label className="mb-3 flex items-center">
                        <input
                          className="form-radio
          h-4
          w-4
          cursor-pointer
          appearance-none
          rounded-full
          border-[2px]
          border-primary-main
          bg-white
          bg-contain
          bg-center
          bg-no-repeat
          p-2
          align-top
          transition
          duration-200
          checked:border-primary-pressed
          checked:bg-primary-main
          focus:outline-none"
                          type="radio"
                          checked={userType === JoinUserType.Brand}
                          onChange={() => setUserType(JoinUserType.Brand)}
                        />
                        <span className="ml-2 text-neutral-50">Brand</span>
                      </label>
                    </div>

                    {userType == JoinUserType.Negosh ? (
                      <div className="mt-3 flex-col ">
                        <label className="mb-3 flex items-center">
                          <input
                            className="form-radio
          h-4
          w-4
          cursor-pointer
          appearance-none
          rounded-full
          border-[2px]
          border-primary-main
          bg-white
          bg-contain
          bg-center
          bg-no-repeat
          p-2
          align-top
          transition
          duration-200
          checked:border-primary-pressed
          checked:bg-primary-main
          focus:outline-none"
                            type="radio"
                            checked={userType === JoinUserType.Negosh}
                            onChange={() => setUserType(JoinUserType.Negosh)}
                          />
                          <span className="ml-2 text-neutral-50">Negosh</span>
                        </label>
                      </div>
                    ) : null}
                  </div>

                  <div className="py-6">
                    <Button
                      size={ButtonSize.full}
                      classes="max-w-[405px] w-full"
                      parentClasses="group"
                      type={
                        phoneIsValid &&
                        emailIsValid &&
                        userType &&
                        companyIsValid &&
                        fullNameIsValid
                          ? ButtonType.primary
                          : ButtonType.disabled
                      }
                      onClick={
                        phoneIsValid &&
                        emailIsValid &&
                        userType &&
                        companyIsValid &&
                        fullNameIsValid
                          ? submitMutationHandler
                          : incompleteFormToast
                      }
                    >
                      Submit Request
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </PageContainer>
    </div>
  );
};
export default Join;
