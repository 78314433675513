import { ConnectButton } from '@rainbow-me/rainbowkit';
import Image from 'next/image';
import React, { useCallback, useEffect, useState } from 'react';
import { FaWallet } from 'react-icons/fa';
import { useAccount, useConnect, useSignMessage } from 'wagmi';
import DividerText from '@/components/UI/DividerText';
import Button, { ButtonSize, ButtonType } from '@/components/UI/buttons/Button';
import LoadingView from '@/components/layout/LoadingView';
import { Email } from '@/components/svg/Email';
import { magicConnector } from '@/utilities/connectors';
import { walletLogin } from '@/utilities/wallet';

interface CustomWalletConnectProps {
  buttonClass?: string;
}

const CustomWalletConnect = (props: CustomWalletConnectProps) => {
  const { signMessageAsync } = useSignMessage();
  // TODO: figure out why chain id is null for magic accounts
  const { address, connector: activeConnector, isConnected } = useAccount();
  const [loading, setLoading] = useState(false);
  // TODO: remove `as any`
  const { connect } = useConnect({
    connector: magicConnector as any,
  });

  const login = useCallback(
    () =>
      walletLogin(
        address ?? '',
        isConnected,
        activeConnector,
        signMessageAsync,
        setLoading,
        connect
      ),
    [address, isConnected, activeConnector, signMessageAsync, connect]
  );

  useEffect(() => {
    if (isConnected && !!address) {
      void login();
    }
  }, [address, isConnected, login]);

  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        const ready = mounted && authenticationStatus !== 'loading';
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === 'authenticated');
        if (
          loading &&
          (authenticationStatus === 'authenticated' || !isConnected)
        ) {
          setLoading(false);
        }

        return (
          <div
            {...(!ready && {
              'aria-hidden': true,
              style: {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (loading) {
                return <LoadingView />;
              } else if (!connected) {
                return (
                  <div className="grid grid-cols-1 gap-2 space-y-2 md:space-y-0">
                    <Button
                      size={ButtonSize.full}
                      type={ButtonType.outline}
                      onClick={connect}
                      classes={props.buttonClass}
                    >
                      <div className="flex w-full items-start">
                        <Email />
                        <p className="pl-4 align-middle">Connect with Email</p>
                      </div>
                    </Button>
                    <DividerText classes={'py-2'} text="OR" />
                    <Button
                      size={ButtonSize.full}
                      type={ButtonType.outline}
                      onClick={openConnectModal}
                      classes={props.buttonClass}
                    >
                      <div className="flex w-full items-center">
                        <div>
                          <FaWallet size={24} />
                        </div>
                        <div>
                          <p className="ml-4 flex-grow text-left">
                            Connect with Wallet
                          </p>
                        </div>
                        <div className="pl-4 align-middle">
                          <Image
                            src="/img/delegateCash.png"
                            width={16}
                            height={16}
                            alt="Delegate Cash Icon"
                            title="Supports delegate.xyz"
                          />
                        </div>
                      </div>
                    </Button>
                  </div>
                );
              }

              if (chain.unsupported) {
                return (
                  <button onClick={openChainModal} type="button">
                    Wrong network
                  </button>
                );
              }

              return <></>;
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};

export default CustomWalletConnect;
