import Image from 'next/image';
import { useRouter } from 'next/router';
import React from 'react';
import Pill, { PillTheme } from '@/components/UI/Pill';
import Button, { ButtonSize, ButtonType } from '@/components/UI/buttons/Button';

interface Props {
  title: string;
  type?: string;
  description: string;
  children?: JSX.Element;
}

const ErrorComponent = ({ title, type, description, children }: Props) => {
  const router = useRouter();
  return (
    <div className="relative flex w-full grow flex-col overflow-y-scroll bg-gradient-to-r from-[#180B36] to-[#180B36] px-4">
      <div className="absolute bottom-0 left-0 flex h-[400px] w-full shrink-0">
        <Image
          src="/img/gridAsset.png"
          layout="fill"
          objectFit="cover"
          alt="hero image background"
        />
      </div>
      <div className="relative flex h-[calc(100vh-509px)] w-full grow flex-col items-center justify-start justify-center">
        <div className="mt-[120px] flex max-w-lg flex-col items-center justify-end md:max-w-3xl">
          <Pill theme={PillTheme.DEFAULT} showDot={false}>
            Something is wrong
          </Pill>
          <h1 className="bg-gradient-to-b from-primary-main via-white to-primary-focus bg-clip-text text-center font-gt-haptik text-heading-1-mobile text-transparent md:text-heading-1">
            {title}
            {!!type && (
              <span className="bg-gradient-to-b from-uniqueFade-light via-uniqueFade-mid to-uniqueFade-dark bg-clip-text font-gt-haptik">
                {' '}
                {type}
              </span>
            )}
          </h1>
          <p className="max-w-3xl pt-8 text-center text-md text-primary-main md:text-lg">
            {description}
          </p>
          <Button
            classes="my-6"
            size={ButtonSize.primary}
            type={ButtonType.primary}
            onClick={() => router.replace('/')}
          >
            Go Home
          </Button>
          {!!children && children}
        </div>
      </div>
      <div className="relative mx-auto flex grow flex-col justify-end md:max-w-3xl">
        <Image
          src="/img/errorAnimation.png"
          width={497}
          height={386}
          objectFit="cover"
          alt="hero image background"
        />
      </div>
    </div>
  );
};

export default ErrorComponent;
