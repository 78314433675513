/** @type {import("tailwindcss").Config} */
const tailwindConfig = {
  content: [
    './pages/**/*.{js,ts,jsx,tsx}',
    './components/**/*.{js,ts,jsx,tsx}',
  ],
  theme: {
    extend: {
      colors: {
        background: '#F9F5FF',
        primary: {
          main: '#8490FF',
          hover: '#6A73CC',
          pressed: '#4F5699',
          focus: '#B5BCFF',
          surface: '#E6E9FF',
        },
        accent: {
          main: '#33336C',
          hover: '#292956',
          pressed: '#1F1F41',
          focus: '#ADADC4',
          surface: '#D6D6E2',
        },
        success: {
          main: '#50CD89',
          hover: '#40A46E',
          pressed: '#307b52',
          focus: '#96E1B8',
          surface: '#DCF5E7',
        },
        error: {
          main: '#F14141',
          hover: '#C13434',
          pressed: '#912727',
          focus: '#F9B3B3',
          surface: '#FCD9D9',
        },
        warning: {
          main: '#F09400',
          hover: '#C07600',
          pressed: '#905900',
          focus: '#F9D499',
          surface: '#FCEACC',
        },
        neutral: {
          100: '#0A0A16',
          90: '#23232D',
          80: '#3B3B45',
          70: '#54545C',
          60: '#6C6C73',
          50: '#85858B',
          40: '#9D9DA2',
          30: '#B6B6B9',
          20: '#CECED0',
          10: '#E7E7E8',
        },
        uniqueFade: {
          light: '#C2C8FF',
          mid: '#B5BCFF',
          dark: '#8490FF',
        },
        brandBlue: '#0d0129',
      },
      // https://github.com/tailwindlabs/tailwindcss/issues/1102
      container: {
        screens: {
          // sm: '100%',
          // md: '100%',
          // lg: '1024px',
          xl: '1120px',
        },
      },
      keyframes: {
        fromRight: {
          '0%': { transform: 'translateX(100%);' },
          '100%': { transform: 'translateX(0%);' },
        },
      },
      backgroundImage: {
        // eslint-disable-next-line quotes
        grid: "url('/img/GridBoxed.svg')",
      },
      animation: {
        toast: 'fromRight .2s ease-in',
      },
    },
    boxShadow: {
      inner: 'inset -5px -5px 250px rgba(255, 255, 255, 0.02)',
    },
    fontFamily: {
      'satoshi-reg': 'Satoshi Reg',
      'satoshi-med': 'Satoshi Med',
      'satoshi-bold': 'Satoshi Bold',
      'satoshi-italic': 'Satoshi Italic',
      'gt-haptik': 'GT Haptik Bold',
    },
    fontWeight: {
      regular: '400',
      medium: '500',
      bold: '700',
    },
    fontSize: {
      sm: ['14px'],
      md: ['16px'],
      lg: ['18px'],
      //desktop
      'heading-1': ['80px'],
      'heading-2': ['56px'],
      'heading-3': ['48px'],
      'heading-4': ['40px'],
      'heading-5': ['32px'],
      'heading-6': ['24px'],
      //mobile
      'heading-1-mobile': ['58px'],
      'heading-2-mobile': ['50px'],
      'heading-3-mobile': ['42px'],
      'heading-4-mobile': ['30px'],
      'heading-5-mobile': ['26px'],
      'heading-6-mobile': ['18px'],
    },
  },
  safelist: [
    'bg-orange-500',
    'bg-purple-500',
    'bg-blue-500',
    'bg-green-500',
    'bg-gray-500',
  ],
  plugins: [require('@tailwindcss/forms'), require('@tailwindcss/typography')],
};

module.exports = tailwindConfig;
