import { createSlice } from '@reduxjs/toolkit';
import type { ToastModel } from '@/components/UI/toast/Toast';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  toasts: [],
};

const toastReducer = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    addToast(state: any, action: PayloadAction<ToastModel>) {
      state.toasts = [...state.toasts, action.payload];
    },
    removeToast(state: any, action: PayloadAction<string>) {
      const tempToasts = [...state.toasts];
      const index = tempToasts.findIndex(
        (toast: ToastModel) => toast.id === action.payload
      );
      tempToasts.splice(index, 1);
      state.toasts = tempToasts;
    },
  },
});

export const { addToast, removeToast } = toastReducer.actions;

export default toastReducer.reducer;
