import React, { useEffect, useState } from 'react';

import { HiInformationCircle } from 'react-icons/hi';
import { IoIosCheckmarkCircle, IoIosWarning, IoMdClose } from 'react-icons/io';
import { useDispatch } from 'react-redux';

import { removeToast } from '@/store/toastSlice';

export interface ToastModel {
  id: string;
  title: string;
  type: ToastType;
  onClose: (id: string) => void;
}

export enum ToastType {
  success = 'success',
  error = 'error',
  info = 'info',
  warning = 'warning',
}

export interface ToastInfo {
  class: string;
  icon: JSX.Element;
}

export const toastIcons: Record<string, ToastInfo> = {
  success: {
    class: 'text-success-main hover:text-success-hover hover:bg-success-focus',
    icon: <IoIosCheckmarkCircle />,
  },
  error: {
    class: 'text-error-main hover:text-error-hover hover:bg-error-focus',
    icon: <IoIosWarning />,
  },
  info: {
    class: 'text-primary-main hover:text-primary-hover hover:bg-primary-focus',
    icon: <HiInformationCircle />,
  },
  warning: {
    class: 'text-warning-main hover:text-warning-hover hover:bg-warning-focus',
    icon: <IoIosWarning />,
  },
};

export const toastClasses: Record<string, { class: string }> = {
  success: {
    class: 'bg-success-surface text-success-main border-success-main',
  },
  error: {
    class: 'bg-error-surface text-error-main border-error-main',
  },
  info: {
    class: 'bg-primary-surface text-primary-main border-primary-main',
  },
  warning: {
    class: 'bg-warning-surface text-warning-main border-warning-main',
  },
};

const Toast = ({ id, title, type, onClose }: ToastModel) => {
  const dispatch = useDispatch();
  const [icon, setIcon] = useState<ToastInfo | null>(null);
  const [classes, setClasses] = useState<{ class: string } | null>(null);

  useEffect(() => {
    setIcon(toastIcons[type]);
    setClasses(toastClasses[type]);
    const interval = setInterval(() => {
      dispatch(removeToast(id));
    }, 3500);
    return () => {
      clearInterval(interval);
    };
  }, [dispatch, id, type]);

  return (
    <div
      className={`flex max-w-xs animate-toast items-center justify-between space-x-4 rounded-lg border-[1px] border-solid px-4 py-4 ${
        classes?.class ?? ''
      }`}
    >
      <div className="flex items-center space-x-3 ">
        {icon && icon.icon}
        <p className="font-bold">{title}</p>
      </div>
      <div
        className={`hover:cursor-pointer ${icon?.class} rounded-sm`}
        onClick={() => onClose(id)}
      >
        <IoMdClose />
      </div>
    </div>
  );
};

export default Toast;
