import React from 'react';
import CustomWalletConnect from '@/components/UI/CustomWalletConnect';
import DrawerButton, { Theme } from '@/components/UI/buttons/DrawerButton';
import { JoinUserType } from '@/pages/join';
import WalletContainer from './WalletContainer';

interface ConnectDrawerProps {
  buttonText: string;
  closeDrawer?: () => void;
}

const ConnectDrawer = ({ closeDrawer }: ConnectDrawerProps) => {
  return (
    <WalletContainer>
      <div className="pt-10">
        <CustomWalletConnect buttonClass="px-4" />
        <div className="space-y-4 pt-10">
          <DrawerButton
            link={`/join?userType=${JoinUserType.Brand}`}
            title="Join as a Brand"
            action={closeDrawer}
            theme={Theme.Primary}
          />
          <DrawerButton
            link={`/join?userType=${JoinUserType.Collection}`}
            title="Join as a Collection"
            action={closeDrawer}
            theme={Theme.Primary}
          />
        </div>
      </div>
    </WalletContainer>
  );
};

export default ConnectDrawer;
