import React, { forwardRef } from 'react';
import type { ReactNode } from 'react';

export enum PillTheme {
  DEFAULT = 'Default',
  NEUTRAL = 'Neutral',
  RED = 'Red',
  ORANGE = 'Orange',
  GREEN = 'Green',
}

// `dotClass` cannot be computed due to Tailwind fuckery
export const pillThemeColors = {
  [PillTheme.DEFAULT]: {
    primary: 'primary-main',
    secondary: 'primary-surface',
    dotClass: 'h-full w-full rounded-full bg-primary-main',
  },
  [PillTheme.GREEN]: {
    primary: 'success-main',
    secondary: 'success-surface',
    dotClass: 'h-full w-full rounded-full bg-success-main',
  },
  [PillTheme.RED]: {
    primary: 'error-main',
    secondary: 'error-surface',
    dotClass: 'h-full w-full rounded-full bg-error-main',
  },
  [PillTheme.NEUTRAL]: {
    primary: 'neutral-50',
    secondary: 'neutral-10',
    dotClass: 'h-full w-full rounded-full bg-neutral-90',
  },
  [PillTheme.ORANGE]: {
    primary: 'warning-main',
    secondary: 'warning-surface',
    dotClass: 'h-full w-full rounded-full bg-warning-main',
  },
};

type StatusProps = {
  children: ReactNode;
  title?: string;
  theme?: PillTheme;
  showDot?: boolean;
  pulse?: boolean;
  classes?: string;
};

const Pill = forwardRef<HTMLDivElement, StatusProps>(
  (
    {
      children,
      title,
      theme = PillTheme.DEFAULT,
      showDot = true,
      pulse = false,
      classes,
    },
    ref
  ) => {
    const { primary, secondary, dotClass } = pillThemeColors[theme];

    return (
      <div
        ref={ref}
        className={`${classes ?? ''}
      text-${primary} 
      bg-${secondary} 
      font-light text-xs m-2 mx-0 inline-flex items-center
      justify-center rounded-lg px-3 py-1`}
        title={title}
      >
        {showDot && (
          <span className="relative mr-2 flex h-2 w-2">
            {pulse && (
              <span
                className={`bg-${primary} absolute inline-flex h-full w-full animate-ping rounded-full opacity-75`}
              />
            )}
            <span className={dotClass} />
          </span>
        )}
        <div>{children}</div>
      </div>
    );
  }
);
Pill.displayName = 'Pill';

export default Pill;
