import { configureStore } from '@reduxjs/toolkit';
import accountReducer from './accountSlice';
import userNFTsReducer from './nftSlice';
import toastReducer from './toastSlice';

export const store = configureStore({
  reducer: {
    account: accountReducer,
    toasts: toastReducer,
    userNFTs: userNFTsReducer,
  },
});

// It allows us to easily type actions that we dispatch to the store.

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
