import React from 'react';
interface BadgeProps {
  count: number;
  bg?: string;
  size?: string;
}

export const AvatarBadge = ({ count, bg = 'bg-primary-main' }: BadgeProps) => {
  let badgeClass = ` lg:h-[19px] px-1 h-[16px] ${bg} -bottom-1 -right-1 absolute rounded-[2px]`;
  if (count > 99) {
    badgeClass = ` lg:h-[19px] px-1  h-[16px] ${bg} -bottom-1 -right-1 absolute rounded-[2px]`;
  }
  return (
    <div className={badgeClass}>
      <div className="grid grid-cols-1 place-content-center text-center text-[11px] text-white lg:text-[13px]">
        {count}
      </div>
    </div>
  );
};
