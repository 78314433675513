import retry from 'async-retry';

export const fetchWithRetries = async (url: string, options?: any) => {
  const result = await retry(
    async () => {
      // Make the API request
      return await fetch(url, options);
    },
    {
      retries: 5, // Number of retries before giving up
      factor: 2, // Exponential factor
      minTimeout: 1000, // Minimum wait time before retrying
      maxTimeout: 60000, // Maximum wait time before retrying
      randomize: true, // Randomize the wait time
    }
  );

  // Return the result
  return result;
};
