import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import CloseButton from '@/components/UI/buttons/CloseButton';

interface Props {
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const Drawer = ({ children, isOpen, setIsOpen }: Props) => {
  const [portalRef, setPortalRef] = useState<Element>();

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  useEffect(() => {
    const close = (e: any) => {
      if (e.key === 'Escape') {
        setIsOpen(false);
      }
    };
    window.addEventListener('keydown', close);
    setPortalRef(document.getElementById('overlays') as Element);
    return () => window.removeEventListener('keydown', close);
  }, [setIsOpen]);

  return (
    <>
      {portalRef != undefined &&
        createPortal(
          <>
            <div
              className={`fixed inset-0 z-40 ${
                isOpen ? 'block' : 'hidden'
              } cursor-pointer bg-black/50 transition duration-300 ease-in-out`}
              onClick={() => setIsOpen(false)}
            />
            <div
              className={`shadow-lg fixed inset-y-0 right-0 z-50 w-full transform bg-background transition duration-300 ease-in-out md:w-[470px] ${
                isOpen ? 'translate-x-0' : 'translate-x-full'
              }`}
            >
              <div className="hidden-scroll relative flex h-full w-full flex-col overflow-y-scroll">
                {children}
                <div className="fixed right-0 px-8 pt-14">
                  <CloseButton onClick={() => setIsOpen(false)} />
                </div>
              </div>
            </div>
          </>,
          portalRef
        )}
    </>
  );
};

export default Drawer;
