import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import React, { useState } from 'react';
import Button, { ButtonSize, ButtonType } from '@/components/UI/buttons/Button';
import Drawer from '@/components/UI/drawer/Drawer';
import WalletConnect from '@/components/WalletConnect';
import Hamburger from '@/components/svg/Hamburger';
import Logo from '@/components/svg/HeaderLogo';
import initStyle from '@/styles/Init.module.scss';
import { UsersRole_Enum } from '@/utilities/__generated__/graphql';

export default function Navbar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState(<></>);

  const { route } = useRouter();

  const headerLight =
    route === '/' || route === '/about' || route === '/401' || route === '/404';
  const linkStyle = `cursor-pointer text-lg header-color-text ${
    headerLight ? 'text-white' : 'text-accent-main'
  }`;

  const { data: session } = useSession();

  const links = [
    { title: 'About', link: 'about' },
    { title: 'Opportunities', link: 'deals' },
  ];

  const listItem = (title: string, link: string) => {
    return (
      <li className="whitespace-nowrap px-4" key={link}>
        <Link className={'flex items-center py-2'} href={`/${link}`}>
          <a
            className={`${linkStyle} ${initStyle.headerHidden} initially-hidden`}
          >
            {title}
          </a>
        </Link>
      </li>
    );
  };

  const walletConnect = (
    <div
      className={`${initStyle.headerHidden} initially-hidden flex flex-row items-center justify-center space-x-3`}
    >
      {session?.token.role === UsersRole_Enum.Brand && (
        <Link href={'/deal/create'}>
          <Button
            classes="hidden md:flex"
            size={ButtonSize.primary}
            type={ButtonType.primary}
          >
            Create Opportunity
          </Button>
        </Link>
      )}
      <WalletConnect
        role={session?.token.role}
        setDrawerContent={setDrawerContent}
        setDrawerOpen={setDrawerOpen}
      />
    </div>
  );

  return (
    <>
      <div
        className={`${
          headerLight ? 'header-light' : 'header-dark'
        } navGradient`}
        id="nav-gradient"
      />
      <nav className={'relative z-10 flex w-full'}>
        <div className="container mx-auto grid w-full grid-cols-2 flex-col items-center justify-center justify-self-start px-4">
          <div className="flex items-center space-x-4">
            <Link href="/" passHref legacyBehavior>
              <a className={initStyle.headerLogo} id="header-logo">
                <Logo
                  fill={headerLight ? '#fff' : '#33336C'}
                  width={'184px'}
                  height={'30px'}
                  className={'fill-primary-main'}
                />
              </a>
            </Link>
            <div className="hidden items-center justify-self-center md:grid">
              <ul className="mr-auto flex flex-row font-regular">
                {links.map(({ title, link }: any) => {
                  return listItem(title, link);
                })}
              </ul>
            </div>
          </div>
          <div
            className="initially-hidden grid items-center justify-self-end hover:cursor-pointer md:hidden"
            onClick={() => setDrawerOpen(true)}
          >
            <Hamburger fill={headerLight ? 'fill-white' : 'fill-accent-main'} />
          </div>
          <div className="grid items-center justify-self-end">
            {walletConnect}
          </div>
        </div>
        <Drawer isOpen={drawerOpen} setIsOpen={setDrawerOpen}>
          {drawerContent}
        </Drawer>
      </nav>
    </>
  );
}
