import { TOKEN_NAME } from '@/constants/constants';

// convert object to string and store in localStorage
export const saveToLocalStorage = (key: string, value: any) => {
  try {
    const serialisedState = JSON.stringify(value);
    localStorage.setItem(key, serialisedState);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e);
  }
};

// load string from localStorage and convert into an Object
// invalid output must be undefined
export const loadFromLocalStorage = () => {
  try {
    const serialisedState = localStorage.getItem(TOKEN_NAME);
    if (serialisedState === null) return undefined;
    return JSON.parse(serialisedState);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e);
    return undefined;
  }
};
